const promocodes = require('./promocodes');
const math = require('./math');
const calculate = require('./calculate');
const currency = require("currency.js");

/**
 *
 * @param {{price_value: Number, price_currency: String}} arrival
 * @param {{discount_size: Number, discount_type: String, discount_currency: String=, discount_type: String=}=} promocode
 * @param {String[]} discountsApplied
 * @param {Number=} priceValue
 * @param {Number=} paidForLength
 * @param {{loyalCustomersAmount: Number, conversionRate: Number}=} loyalDiscountData - if priceValue is in Euros: pass 1 as conversionRate
 *                                                                                      if <currency>: pass actual euro/<currency> conversion rate
 * @return {{discountSize: number, currency: (string|*), type: (string|*), absoluteSize: (number|*), extraInfo: (string|*)}}
 */
function calculateDiscount(
    arrival,
    promocode,
    discountsApplied = [],
    priceValue = undefined,
    paidForLength = 1,
    loyalDiscountData = {loyalCustomersAmount: 0, conversionRate: 1}
) {
    const price = priceValue || arrival.price_value;
    let extraInfo = '';
    const discountValues = discountsApplied.map(discountName => (
        {
            name: discountName,
            value: math.calculateAbsoluteDiscountSize(
                arrival[discountName + '_size'],
                arrival[discountName + '_type'],
                price),
            type: arrival[discountName + '_type'],
            currency: arrival.price_currency
        }
    ))
    const promoDiscount = {
        name: 'promocode',
        value: promocode ? promocodes.calculatePromoDiscount(price, promocode) : 0,
        type: promocode ? promocode.discount_type : undefined,
        currency: promocode ? promocode.discount_currency : undefined,
    };

    const loyalDiscount = {
        name: 'loyal',
        value: currency(loyalDiscountData.loyalCustomersAmount)
            .multiply(calculate.convertPrice(50, loyalDiscountData.conversionRate)).value,
        type: 'summ',
        currency:  'EUR',
    }
    const certificateDiscount = {
        name: 'certificate',
        value: 0, // TODO сделать скидку за сертификат
        type: 'summ',
        currency:  'EUR',
    }

    let discountsArray = [...discountValues, promoDiscount, loyalDiscount, certificateDiscount];
    let absoluteDiscount = discountsArray
        .reduce((maxDiscount, curVal) => maxDiscount.value > curVal.value ? maxDiscount : curVal);
    let relativeDiscountSize = math.convertAbsoluteToRelative(absoluteDiscount.value, price);

    if (absoluteDiscount.value > price) {
        absoluteDiscount.value = price;
        relativeDiscountSize = 100;
    }

    return {
        discountSize: relativeDiscountSize,
        absoluteSize: absoluteDiscount.value,
        extraInfo: absoluteDiscount.value ? absoluteDiscount.name : '',
        type: absoluteDiscount.type,
        currency:  absoluteDiscount.currency,
    };
}


module.exports = {
    calculateDiscount
}