import { injected, token } from 'brandi';
import container from '../ioc';
import { API_URL } from './useApi';
import { IApiModel } from './index.interface';
import rateLimit from 'axios-rate-limit';
import axios, { Method } from 'axios';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { getCookie, eraseCookie } from '../utils/cookies';
import { emptyGlobalState } from './globalState';
import { GlobalModelStoreToken, IGlobalStateModel } from '../models/global';
import { makeAutoObservable } from 'mobx';

const http = rateLimit(axios.create(), {maxRequests: 6, perMilliseconds: 1000});

export default class ApiModel implements IApiModel {
  constructor(private globalState: IGlobalStateModel) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  request<R>(method: Method, url: string, data = {}, isRetry = false): Promise<R> {
    const headers: any = {};

    const token = getCookie('SV_LOGIN');

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    return http({
      method,
      url: `${API_URL}${url}`,
      params: method.toLowerCase() === 'get' ? data : {},
      data: method.toLowerCase() === 'get' ? {} : data,
      headers,
    })
      .then((response) => response.data)
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          if (!isRetry) {
            return this.updateAccessToken()
              .then(() => {
                return this.request(method, url, data, true);
              });
          } else {
            this.logout();
          }
        }

        throw error;
      })
      .catch(this.errorHandler);
  }

  rawRequest<R>(method: Method, url: string, data = {}): Promise<R> {
    return http({
      method: method,
      url: `${API_URL}${url}`,
      params: method.toLowerCase() === 'get' ? data : {},
      data: method.toLowerCase() === 'get' ? {} : data,
    })
      .then((response) => response.data)
      .catch(this.errorHandler);
  }

  private errorHandler(error: any) {
    if (error.response) {
      // Internal server error
      if (error.response.status === 500) {
        toast('Something went wrong. Try to change data or return later', {type: 'error'})
        throw error;
      }

      if (error.response.status === 404) {
        if (window.location.pathname.includes('/school'))
          window.location.href = `${window.location.pathname.split('/').slice(0, 3).join('/')}?people=1&step=1`;
        else
          window.location.href = '/';
        throw error;
      }

      if (error.response.data && error.response.data.message === 'Invalid token.') {
        eraseCookie('SV_LOGIN');
        throw error;
      }

      // Forbidden, Not Found.
      if (error.response.data) {
        const msg = error.response.data.message || error.response.data
        toast(this.translateError(msg), {type: 'error'})
        throw error;
      }

      // Usual errors
      if (error.response.data?.errors) {
        const {data: {errors}} = error.response.data
        for (const e in errors)
          errors[e].forEach((msg: string) => toast(msg, {type: 'error'}))
        throw error;
      }
    }
    toast('Something wrong with request. Try again later', {type: 'error'})
    throw error;
  }

  private translateError = (err: string) => {
    switch (err) {
      case 'Not Found':
        return t('api.useApi.notFound')
      default:
        return err;
    }
  }

  private updateAccessToken = async () => {
    const state = this.globalState.get();
    if(!state.userRefreshToken) return state;

    return http.post(
      `${API_URL}/auth/refreshToken`,
      {token: state.userRefreshToken},
      {},
    )
      .then((response) => {
        const newState = {
          ...state,
          userAccessToken: response.data.jwt,
          userRefreshToken: response.data.refresh
        };

        this.globalState.set(newState);

        return newState;
      })
      .catch((error) => {
        this.globalState.set(emptyGlobalState);
        toast('Произошла ошибка при авторизации! Попробуйте перезайти.', { type: 'error' });
        throw error;
      });
  }

  private logout = () => {
    this.globalState.set(emptyGlobalState);
    eraseCookie('hasCarrotSync');
  }
}

export const ApiModelStoreToken = token<IApiModel>('ApiModelStoreToken');

container.bind(ApiModelStoreToken).toInstance(ApiModel).inSingletonScope();

injected(ApiModel, GlobalModelStoreToken);