export const resizeImage = (image, maxWidth, maxHeight, maxPixels) => {
  if (image.width < maxWidth && image.height < maxHeight && image.width * image.height < maxPixels) return true;
  const imageProportion = image.width / image.height;

  if (image.width * image.height > maxPixels) {
    image.height = Math.sqrt(maxPixels / imageProportion);
    image.width = image.height * imageProportion;
  }
  if (image.width > image.height) {
    if (image.width > maxWidth) {
      image.height = image.height * (maxWidth / image.width);
      image.width = maxWidth;
    }
  } else {
    if (image.height > maxHeight) {
      image.width = image.width * (maxHeight / image.height);
      image.height = maxHeight;
    }
  }

  return false;
}

export const getImageSize = (base64Src) => {
  let endingOffset = 1;
  const encodingCoefficient = 0.75  // base64 encodes 3 bytes of binary data on 4 chars
  if (base64Src.endsWith('==')) {
    endingOffset = 2
  }
  const sizeInBytes = (base64Src.length - endingOffset) * encodingCoefficient;
  return sizeInBytes / 1024;
}


export const imageDataToBlob = (imageData) => {
  const block = imageData.split(";");
  // Get the content type of the image
  const contentType = block[0].split(":")[1];// In this case "image/gif"
  // get the real base64 content of the file
  const realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."
  // Convert it to a blob to upload
  return b64toBlob(realData, contentType);
}

export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, {type: contentType});
}