import {useEffect, useState} from "react";
import {Row, Col} from 'reactstrap';
import {Link} from "react-router-dom";
import PaIcon from "../../../../styles/assets/images/menu/pa-icon.png";
import styles from "./index.module.scss";
import arrow from "../../../../styles/assets/images/sv/NM-arrow.svg";

const MenuPersonalAccount = ({userData, to, isActive, onClick}) => {
    const [nickname, setNickname] = useState("")
    const [email, setEmail] = useState("")

    useEffect(() => {
        setNickname(userData?.nickname);
        setEmail(userData?.email);
    },[userData])

    return (
        <Link className={styles.personal_account__wrapper} to={to} onClick={onClick}>
            <Col className={`${styles.personal_account__logo_content} ${isActive ? `${styles.personal_account__logo_active}` : ""}`} xs="mt-1">
                <Row className={`justify-content-start align-items-center ${styles.personal_account__logo_body}`}>
                    <Col className={`${styles.personal_account__logo_wrapper} col-auto`}>
                        <span className={styles.personal_account__logo}>
                            <Link to="/tablet">
                                <img className={styles.personal_account__logo_icon} src={PaIcon} alt=""/>
                            </Link>
                        </span>
                    </Col>
                    <Col className={styles.wrapper__content}>
                        <Row>
                            <div className={styles.wrapper__title}>
                                <h2 className={styles.personal_account__title}>{nickname}</h2>
                            </div>
                        </Row>
                        <Row>
                            <div className={styles.wrapper__subtitle}>
                                <p className={styles.personal_account__subtitle}>{email}</p>
                            </div>
                        </Row>
                    </Col>
                    <Col className="pl-0 pl-sm-4 " style={{width: '20%'}}>
                        <div className="d-flex justify-content-end">
                            <img src={arrow} alt=""/>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Link>
    )
}

export default MenuPersonalAccount;