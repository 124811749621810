const {EVENT_TYPES} = require("./events");
const {PRODUCT_TYPES} = require("./certificates");

const APPLICABLE_PRODUCTS = {
    PRODUCTS: 'products',
    ADVENTURES: 'adventures_all',
    ADVENTURES_RUSSIA: 'adventures_russia',
    ADVENTURES_WORLD: 'adventures_world',
    BASES: 'bases',
    TRAINING: EVENT_TYPES.TRAINING,
    SPECIAL: EVENT_TYPES.SPECIAL,
    RACE:  EVENT_TYPES.RACE,
    INDIVIDUAL_LUCH: EVENT_TYPES.INDIVIDUAL_LUCH,
    BASE_CHAMPIONSHIP: EVENT_TYPES.BASE_CHAMPIONSHIP,
    GRAN_PRIX:  EVENT_TYPES.GRAN_PRIX,
    UNIQUE:  EVENT_TYPES.UNIQUE,
    YACHTS: EVENT_TYPES.YACHTS,
    MATCH_TRIP: EVENT_TYPES.MATCH_TRIP,
    EVENTS_TRIP: 'events_trip',
    GROUPS_ALL: 'groups_all',
    GROUPS_THEORY: 'groups_theory',
    GROUPS_PRACTICE: 'groups_practice',
    GROUPS_LECTURES: 'groups_lectures',
    CAMPS: 'camps_all',
    CAMPS_RUSSIA: 'camps_russia',
    CAMPS_WORLD: 'camps_world',
    BASICS_OF_SAILING: 'basics_of_sailing',
    RACE_FOR_BEGINNERS: 'race_for_beginners',
    SAIL_GP: EVENT_TYPES.SAIL_GP,
    COURSES: 'courses',
    SUBSCRIPTIONS: PRODUCT_TYPES.SUBSCRIPTIONS,
    SAILING_UPSKILL: EVENT_TYPES.SAILING_UPSKILL,
    SAILING_WEEKEND: EVENT_TYPES.SAILING_WEEKEND,
    BASICS_OF_RACING: EVENT_TYPES.BASICS_OF_RACING,
}

const PROMOCODE_PRODUCT_TYPE = {
    EVENTS: 'events',
    ADVENTURES: 'adventures',
    SLOTS: PRODUCT_TYPES.SLOTS,
    ARRIVALS: PRODUCT_TYPES.ARRIVALS,
    GROUPS: PRODUCT_TYPES.GROUPS,
}

module.exports = {APPLICABLE_PRODUCTS, PROMOCODE_PRODUCT_TYPE};
