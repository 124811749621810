const currencyjs = require('currency.js')

/**
 * @param {Number} minuend
 * @param {Number} subtrahend
 * @return {number}
 */
const subtract = (minuend, subtrahend) => currencyjs(minuend).subtract(subtrahend).value;

/**
 * @param {Number} absoluteDiscountSize
 * @param {Number} price
 * @return {number}
 */
const convertAbsoluteToRelative = (absoluteDiscountSize, price) => {
    const discount = currencyjs(absoluteDiscountSize).multiply(100).divide(price).value;
    return (discount > 1)
        ? Math.floor(discount)
        : discount
}

/**
 * @param {Number} discountSize
 * @param {'summ' || 'percent'} discountType
 * @param {Number} priceValue
 * @return {Number}
 */
const calculateAbsoluteDiscountSize = (discountSize, discountType, priceValue) =>
    discountType === 'summ'
        ? discountSize
        : currencyjs(discountSize).multiply(priceValue).divide(100).value


module.exports = {
    calculateAbsoluteDiscountSize,
    convertAbsoluteToRelative,
    subtract
}