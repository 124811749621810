import styles from "./style.module.scss";
import {t} from "i18next";
import FormButton from "../../generic/buttons/main";
import { usePrivateFileApi } from '../../../lib/api/privateFile';
import { useEffect, useState } from 'react';
import { API_URL } from '../../../lib/api/useApi';
import { FILE_MAX_SIZE } from '../../../lib/utils/constants';
import compressImage from "sv-common/helpers/frontend/compressImage";
import Dropzone from '../../generic/Dropzone';

const UploadingDocuments = ({title, subtitle, type, extra, dropZoneType}) => {
    const { history, upload, deleteEntry } = usePrivateFileApi();

    const [files, setFiles] = useState([]);
    const [error, setError] = useState();
    const [isDropzoneVisible, setDropzoneVisible] = useState(false);

    const file = files[0];

    useEffect(() => {
        history({ type, ...extra }).then(setFiles);
    }, []);

    const onDelete = (item) => {
        const itemIndex = files.findIndex(i => i.id === item.id);
        if (itemIndex >= 0 ) {
            deleteEntry(item.id).then(() => {
                setFiles([
                    ...files.slice(0, itemIndex),
                    ...files.slice(itemIndex + 1),
                ]);
                setDropzoneVisible(false);
            });
        }
    }

    const onUpload = (value) =>  {
        value.preview && compressImage(value, 1000, 1000, 5_000_000, (file) => {
            upload({ files: file, type, ...extra }).then(res => {
                setFiles([res, ...files]);
                setError(null);
                setDropzoneVisible(true);
            }).catch(err => {
                setError(err.response.data.message);
            });
        });

        if (!value.preview && !value.fieldName && value.file) {
            onDelete(value.file);
        }
    }

    const handleButtonClick = () => {
        setDropzoneVisible(true);
    };

    return (
        <div className={styles.uploading_documents__wrapper}>
            <div className={styles.uploading_documents__content}>
                <div className={styles.uploading_documents__title_wrapper}>
                    <h5 className={styles.uploading_documents__title}>
                        {title}
                    </h5>
                </div>
                <div className={styles.uploading_documents__subtitle_wrapper}>
                    <p className={styles.uploading_documents__subtitle}>
                        {subtitle}
                    </p>
                </div>
            </div>
            {isDropzoneVisible && (
                <div className={styles.dropzone__wrapper}>
                    <Dropzone
                        accept={{ "image/*,.pdf": [] }}
                        maxSize={FILE_MAX_SIZE}
                        extFile={file && { preview: API_URL + file.url, ext: file.ext, ...file }}
                        callback={onUpload}
                        onError={setError}
                        type={dropZoneType}
                    />
                </div>
            )}
            {error && <span className={"error-label"}>{error}</span>}
            <div className={styles.uploading_documents__btn_wrapper}>
                <FormButton
                    value={t('profile.data.passportFilesBtn')}
                    type="button"
                    className={styles.uploading_documents__btn}
                    onClick={handleButtonClick}
                />
            </div>
        </div>
    )
}

export default UploadingDocuments;