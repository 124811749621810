let currentLocation = window.location.href;
const paramsHandler = {
    date_main: { URL_Date: currentLocation, "277269962_to-parse": `${currentLocation};${Date.now()}` },
    name_main: { URL_Name: currentLocation, "289167522_to-parse": `${currentLocation};${Date.now()}` },
    confirm_main: { URL_Confirm: currentLocation, "289167723_to-parse": `${currentLocation};${Date.now()}` },
    purchase_main: { URL_Purchase: currentLocation, "277269964_to-parse": `${currentLocation};${Date.now()}` }
};

export const sendYandexMetrikaEvent = (target) => {
    const MAIN_METRIKA_ID = process.env.REACT_APP_YA_ID;

    if (window.ym) {
        window.ym(MAIN_METRIKA_ID, 'reachGoal', target, paramsHandler[target] || {});
    } else {
        console.warn('Yandex Metrika is not loaded');
    }
};