import {Col, Row} from "reactstrap";
import {t} from "i18next";
import styles from "./index.module.scss";

const Footer = () => {
    return (
        <footer className={styles.footer__wrapper}>
            <Col className={styles.footer__content}>
                <Row>
                    <div className={styles.footer__wrapper_title}>
                        <h2 className={styles.footer__title}>{t('profile.navigationMenu.footer.title')}</h2>
                    </div>
                </Row>
                <Row>
                    <div className={styles.footer__wrapper_subtitle}>
                        <p className={styles.footer__subtitle}>{t('profile.navigationMenu.footer.subtitle.firstSubtitle')}
                            <a href={t('subscriptions.telegramLink')} className={styles.footer__subtitle_link} target="_blank">{t('profile.navigationMenu.footer.subtitle.link')}</a>{t('profile.navigationMenu.footer.subtitle.secondSubtitle')}
                            <a href={`tel:${t('subscriptions.phone')}`} className={styles.footer__subtitle_link}>{t('subscriptions.phone')}</a>
                        </p>
                    </div>
                </Row>
            </Col>
        </footer>
    )
}

export default Footer;