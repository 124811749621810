import {Row, Col} from 'reactstrap';
import {Link} from "react-router-dom";
import placeholderIcon from "../../../../styles/assets/images/sv/placeholder__icon.svg";
import placeholderIconActive from "../../../../styles/assets/images/sv/placeholder__icon-active.svg";
import arrow from "../../../../styles/assets/images/sv/sidebar-arrow.svg";
import arrowActive from "../../../../styles/assets/images/sv/sidebar-arrow-active.svg";
import styles from "./index.module.scss";

const NavigationLink = ({to, name, isActive}) => {
    return (
        <li className={`${styles.navigation_link__wrapper} ${isActive ? `${styles.navigation_link__active}` : ""}`}>
            <Link to={to} className={styles.navigation_link__body}>
                <Row className="justify-content-center align-items-center">
                    <Col className={`${styles.navigation_link__logo_wrapper} col-auto`}>
                        <img className={styles.navigation_link__logo_icon} src={`${isActive ? placeholderIconActive : placeholderIcon}`} alt=""/>
                    </Col>
                    <div className={styles.navigation_link__content}>
                        <Col className={styles.navigation_link__title_wrapper}>
                            <div className={styles.wrapper__title}>
                                <h2 className={styles.navigation_link__title}>{name}</h2>
                            </div>
                        </Col>
                        <Col className={styles.navigation_link__arrow} style={{width: '20%'}}>
                            <div className="d-flex justify-content-end">
                                <img src={`${isActive ? arrowActive : arrow}`} alt=""/>
                            </div>
                        </Col>
                    </div>
                </Row>
            </Link>
        </li>
    )
}

export default NavigationLink