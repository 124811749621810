import styles from "../../../../components/profile/NavigationMenu/index.module.scss";
import useUserData from "../../../utils/hooks/useUserData";
import NavigationMenu from "../../../../components/profile/NavigationMenu/index";
import { Col, Row, Container } from "reactstrap";

const WithProfileNavigation = ({children}) => {
    const userData = useUserData();

    return (
        <Container className={"pl-0 pr-0"}>
            <Row className={"min-vh-100 no-gutters"}>
                <Col xs={6} className={`min-vh-100 fixed-top ${styles.navigation_menu__wrapper}`}>
                    <NavigationMenu userData={userData}/>
                </Col>
            </Row>
            <Row className="main-panel">
                <Col className={"offset-sm-6 min-vh-100 fixed-top d-none d-lg-block"}>
                    {children}
                </Col>
            </Row>
        </Container>
    )
}

export default WithProfileNavigation