const {calculateAbsoluteDiscountSize} = require('./math');
/**
 *
 * @param {Number} price
 * @param {{discount_size: Number, discount_type: String}} promocode
 */
function calculatePromoDiscount(price, promocode) {
    return calculateAbsoluteDiscountSize(promocode.discount_size, promocode.discount_type, price);
}



module.exports = {
    calculatePromoDiscount
}