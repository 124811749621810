const Price = {
    calculate: require('./price/calculate'),
    adventures: require('./price/adventures'),
    math: require('./price/math'),
    sport: require('./price/sport'),
    groups: require('./price/groups'),
    subscriptions: require('./price/subscriptions'),
};

const Constants = {
    CURRENCIES: require('./constants/currencies'),
    EXPORT: require('./constants/export'),
    CERTIFICATES: require('./constants/certificates'),
    STATUSES: require('./constants/statuses'),
    PAYMENT_SYSTEMS: require('./constants/paymentSystems'),
    promocodes: require('./constants/promocodes'),
    groups: require('./constants/groups'),
    feedback: require('./constants/feedback'),
    TRANSACTION_STATUS: require('./constants/transactionStatuses'),
    PRIVATE_FILE_TYPES: require('./constants/privateFileTypes'),
    CARROT_EVENT_TYPES: require('./constants/carrotEventTypes'),
}

const Functions = {
    CERTIFICATES: require('./functions/certificates')
}

const Helpers = require('./helpers');

module.exports = {Price, Constants, Functions, Helpers};