const { GROUP_TYPES_MAP } = require('./groups');
const COUNTRIES_KEYS = {
    RUSSIA: 'russia',
    UAE: 'uae',
}

const COUNTRIES = [
    {key: 'russia', label: 'Россия'},
    {key: 'uae', label: 'ОАЭ'}
];

const COUNTRIES_EN = [
    {key: 'russia', label: 'Russia'},
    {key: 'uae', label: 'UAE'}
];

const COUNTRIES_LANG_OBJECT = {
  ru: COUNTRIES,
  en: COUNTRIES_EN,
}

const PRODUCT_TYPES = {
    SLOTS: 'slots',
    ARRIVALS: 'arrivals',
    GROUPS: 'groups',
    SUBSCRIPTIONS: 'subscriptions',
    CHILDREN: 'children',
    SHOP: 'shop',
    PRACTICES: 'practices',
    GLAMPING: 'glamping',
    CAMPS: 'camps',
    CHARTERS: 'charter',
    UNIVERSAL: 'universal'
}

const RU_HIDDEN_PRODUCT_TYPES = {
    [GROUP_TYPES_MAP.THEORY]: 'Теории',
    [GROUP_TYPES_MAP.LECTURE]: 'Лекторий',
}

const EN_HIDDEN_PRODUCT_TYPES = {
    [GROUP_TYPES_MAP.THEORY]: 'Theory',
    [GROUP_TYPES_MAP.LECTURE]: 'Lectures',
}

const HIDDEN_PRODUCT_TYPES_LANG_ADAPTER = {
    ru: RU_HIDDEN_PRODUCT_TYPES,
    en: EN_HIDDEN_PRODUCT_TYPES,
}

const RU_PRODUCT_TYPES = {
    [PRODUCT_TYPES.SLOTS]: 'Яхтинг в городе',
    [PRODUCT_TYPES.ARRIVALS]: 'Путешествия',
    [PRODUCT_TYPES.GROUPS]: 'Школа капитанов',
    [PRODUCT_TYPES.SUBSCRIPTIONS]: 'Абонемент',
    [PRODUCT_TYPES.CHILDREN]: 'Детский клуб',
    [PRODUCT_TYPES.SHOP]: 'Магазин',
    [PRODUCT_TYPES.PRACTICES]: 'Практики',
    [PRODUCT_TYPES.GLAMPING]: 'Глэмпинг',
    [PRODUCT_TYPES.CAMPS]: 'Лагеря',
    [PRODUCT_TYPES.CHARTERS]: 'Чартер',
    [GROUP_TYPES_MAP.THEORY]: 'Теории',
    [GROUP_TYPES_MAP.LECTURE]: 'Лекторий',
    [PRODUCT_TYPES.UNIVERSAL]: 'Универсальный'
}

const EN_PRODUCT_TYPES = {
    [PRODUCT_TYPES.SLOTS]: 'Yachting in the city',
    [PRODUCT_TYPES.ARRIVALS]: 'Adventures',
    [PRODUCT_TYPES.GROUPS]: 'School of Captains',
    [PRODUCT_TYPES.SUBSCRIPTIONS]: 'Subscription',
    [PRODUCT_TYPES.CHILDREN]: 'Children\'s Club',
    [PRODUCT_TYPES.SHOP]: 'Shop',
    [PRODUCT_TYPES.PRACTICES]: 'Practices',
    [PRODUCT_TYPES.GLAMPING]: 'Glamping',
    [PRODUCT_TYPES.CAMPS]: 'Camps',
    [PRODUCT_TYPES.CHARTERS]: 'Charter',
    [GROUP_TYPES_MAP.THEORY]: 'Theory',
    [GROUP_TYPES_MAP.LECTURE]: 'Lectures',
    [PRODUCT_TYPES.UNIVERSAL]: 'Universal'
}

const PRODUCT_TYPES_LANG_ADAPTER = {
    ru: RU_PRODUCT_TYPES,
    en: EN_PRODUCT_TYPES,
}

const RU_PRODUCT_TYPES_ACCUSATIVE = {
    [PRODUCT_TYPES.SLOTS]: 'Спорт',
    [PRODUCT_TYPES.ARRIVALS]: 'Путешествия',
    [PRODUCT_TYPES.CHILDREN]: 'Детский клуб',
    [PRODUCT_TYPES.SHOP]: 'Магазин',
    [PRODUCT_TYPES.SUBSCRIPTIONS]: 'Абонемент',
    [PRODUCT_TYPES.GROUPS]: 'Школу капитанов',
    [PRODUCT_TYPES.CAMPS]: 'Лагеря',
}

const EN_PRODUCT_TYPES_ACCUSATIVE = {
    [PRODUCT_TYPES.SLOTS]: 'Sport',
    [PRODUCT_TYPES.ARRIVALS]: 'Journeys',
    [PRODUCT_TYPES.CHILDREN]: 'Children\'s Club',
    [PRODUCT_TYPES.SHOP]: 'Shop',
    [PRODUCT_TYPES.SUBSCRIPTIONS]: 'Subscription',
    [PRODUCT_TYPES.GROUPS]: 'School of Captains',
    [PRODUCT_TYPES.CAMPS]: 'Camps',
}

const PRODUCT_TYPES_ACCUSATIVE_LANG_ADAPTER = {
    ru: RU_PRODUCT_TYPES_ACCUSATIVE,
    en: EN_PRODUCT_TYPES_ACCUSATIVE,
}

const ERRORS = {
    NOT_EXIST: 'Not exist',
    NOT_ACTIVE: 'Not active',
    INCORRECT_PRODUCT_TYPE: 'Incorrect product type',
}

const RU_ERROR_MESSAGES = {
    [ERRORS.NOT_EXIST]: 'Сертификат не существует',
    [ERRORS.NOT_ACTIVE]: 'Сертификат не активен',
    [ERRORS.INCORRECT_PRODUCT_TYPE]: 'Нельзя использовать данный тип сертификата',
}

const EN_ERROR_MESSAGES = {
    [ERRORS.NOT_EXIST]: 'The Gift Card does not exist',
    [ERRORS.NOT_ACTIVE]: 'The Gift Card is not active',
    [ERRORS.INCORRECT_PRODUCT_TYPE]: 'You cannot use this type of The Gift Card',
}

const ERROR_MESSAGE_LANG_ADAPTER = {
    ru: RU_ERROR_MESSAGES,
    en: EN_ERROR_MESSAGES
}

const QUEUE_STATUSES = {
    ADDED: 'added',
    SENT: 'sent',
    ERROR: 'error'
};

const QUEUE_STATUSES_TRANSLATIONS = {
    [QUEUE_STATUSES.ADDED]: 'Ожидает отправки',
    [QUEUE_STATUSES.SENT]: 'Отправлено успешно',
    [QUEUE_STATUSES.ERROR]: 'Ошибка отправки'
};

const EN_QUEUE_STATUSES_TRANSLATIONS = {
    [QUEUE_STATUSES.ADDED]: 'Waiting to be sent',
    [QUEUE_STATUSES.SENT]: 'Sent successfully',
    [QUEUE_STATUSES.ERROR]: 'Sending error'
};

const QUEUE_STATUSES_TRANSLATIONS_LANG_ADAPTER = {
    ru: QUEUE_STATUSES_TRANSLATIONS,
    en: EN_QUEUE_STATUSES_TRANSLATIONS
}

const CERTIFICATE_TYPES = {
    DEFAULT: 'default',
    CORPORATE: 'corporate'
}

const RU_CERTIFICATE_TYPES = {
    [CERTIFICATE_TYPES.DEFAULT]: 'По умолчанию',
    [CERTIFICATE_TYPES.CORPORATE]: 'Корпоративный'
}

const EN_CERTIFICATE_TYPES = {
    [CERTIFICATE_TYPES.DEFAULT]: 'Default',
    [CERTIFICATE_TYPES.CORPORATE]: 'Corporate',
    [CERTIFICATE_TYPES.UNIVERSAL]: 'Universal'
}

const CERTIFICATE_TYPES_LANG_ADAPTER = {
    ru: RU_CERTIFICATE_TYPES,
    en: EN_CERTIFICATE_TYPES,
}

const REASON_TYPES = {
  PURCHASE: 'purchase',
  MANUAL: 'manual',
  OPERATOR_DOWNLOAD: 'operator_download',
  TYPE_CHANGE: 'type_change',
  UPDATE: 'update',
  CREATE: 'create',
  COMMENT: 'comment',
};

module.exports = {
    COUNTRIES,
    COUNTRIES_KEYS,
    PRODUCT_TYPES,
    RU_PRODUCT_TYPES,
    RU_PRODUCT_TYPES_ACCUSATIVE,
    ERRORS,
    ERROR_MESSAGE_LANG_ADAPTER,
    QUEUE_STATUSES,
    QUEUE_STATUSES_TRANSLATIONS,
    PRODUCT_TYPES_LANG_ADAPTER,
    HIDDEN_PRODUCT_TYPES_LANG_ADAPTER,
    QUEUE_STATUSES_TRANSLATIONS_LANG_ADAPTER,
    PRODUCT_TYPES_ACCUSATIVE_LANG_ADAPTER,
    CERTIFICATE_TYPES,
    RU_CERTIFICATE_TYPES,
    EN_CERTIFICATE_TYPES,
    CERTIFICATE_TYPES_LANG_ADAPTER,
    REASON_TYPES,
    COUNTRIES_LANG_OBJECT
};
