export const routes = [
    {
        headerProfile: [
            {
                to: "/me/personal-data",
            }
        ],
        sidebarProfile: [
            {
                title: "profile.navigationMenu.routes.events",
                to: "/me/events",
            },
            {
                title: "profile.navigationMenu.routes.certificates",
                to: "/me/certificates",
            },
            {
                title: "profile.navigationMenu.routes.seasonTicket",
                to: "/me/season-ticket",
            },
            {
                title: "profile.navigationMenu.routes.notifications",
                to: "/me/notifications",
            },
            {
                title: "profile.navigationMenu.routes.supportService",
                to: "/me/support-service",
            },
            {
                title: "profile.navigationMenu.routes.accountActions",
                to: "/me/account-actions",
            }
        ]
    }

];

const flattenRoutesFun = (source, parent) =>
    source.reduce((acc, route) => {
        const builtRoute = {
            title: route.title,
            to: route.to,
            icon: route.icon || (parent && parent.icon) || "",
            breadcrumbs: ((parent && parent.breadcrumbs) || []).concat([route.title])
        };
        return acc.concat(builtRoute).concat(flattenRoutesFun(route.children || [], builtRoute))
    }, []);

const flattenRoutes = flattenRoutesFun(routes).reverse();

export const getCurrentRoute = to =>
    flattenRoutes.find(route => to.startsWith(route.to));