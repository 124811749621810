import {Row, Col} from 'reactstrap';
import styles from './index.module.scss'
import SVLogo from "../../../../styles/assets/images/sv/sv_logo-white.svg";
import WRLogoWhite from "../../../../styles/assets/images/menu/WRLogoWhite.svg";
import messageIcon from "../../../../styles/assets/images/sv/message-icon.svg"
import {BASE_SILAVETRA_URL} from "../../../../lib/api/globalState";
import {t} from "i18next";
import {useApi} from "../../../../lib/api/useApi";
import {Link} from "react-router-dom";
import {isRus} from "../../../../lib/utils/country";

const Header = ({label}) => {
    const { getGlobalState } = useApi();
    const globalState = getGlobalState();
    const isRoleTablet = globalState.clientRole === 'tablet';
    const logo = isRus ? SVLogo : WRLogoWhite

    return (
        <Row className={`header justify-content-start align-items-top no-gutters ${styles.header_navigation__wrapper} ${styles.header_navigation_menu} ${styles.header_white}`}>
            <Col className={styles.header__logo_content} xs="mt-1">
                <Row className={`justify-content-start align-items-center ${styles.header__logo_body}`}>
                    <Col className="col-auto header__logo-wrapper">
                    <span className="header__logo">
                        {isRoleTablet ?
                            <Link to="/tablet">
                                <img src={logo} alt="" />
                            </Link>
                            :
                            <a href={BASE_SILAVETRA_URL} target="_blank">
                                <img src={logo} alt="" />
                            </a>
                        }
                    </span>
                    </Col>
                    <Col className="col-auto d-flex align-items-center justify-content-center">
                        {isRoleTablet ?
                            <Link to="/tablet" className={`${styles.header__link} ${styles.header__link_white}`}>
                                <span className={`${styles.header__step} ${styles.header__step_white}`}>{t(label)}</span>
                            </Link>
                            : <span className={`header__step ${styles.header__step_white}`}>{t(label)}</span>
                        }
                    </Col>
                </Row>
            </Col>
            <Col className={`pl-0 pl-sm-4 ${styles.header_navigation__icon}`}>
                <Link to="/tablet" className="d-flex justify-content-end">
                    <img src={messageIcon} alt=""/>
                </Link>
            </Col>
        </Row>
    )
}

export default Header;