const TRANSACTION_STATUS = {
  INITIALIZED: 'initialized',
  INVOICE_CREATED: 'invoice_created',
  AUTHORIZED: 'authorized',
  CONFIRMED: 'confirmed',
  ORDER_EXPIRED: 'order_expired',
  INVOICE_EXPIRED: 'invoice_expired',
  REVERSED: 'reversed',
  REFUNDED: 'refunded',
  PARTIAL_REFUNDED: 'partial_refunded',
  REJECTED: 'rejected',
  THREEDS_CHECKING: 'threeds_checking',
  TINKOFF_INSTALLMENT_NEW: 'tinkoff_installment_new',
  TINKOFF_INSTALLMENT_INPROGRESS: 'tinkoff_installment_inprogress',
  TINKOFF_INSTALLMENT_APPROVED: 'tinkoff_installment_approved',
  TINKOFF_INSTALLMENT_SIGNED: 'tinkoff_installment_signed',
  TINKOFF_INSTALLMENT_CANCELED: 'tinkoff_installment_canceled',
  TINKOFF_INSTALLMENT_REJECTED: 'tinkoff_installment_rejected',
}

module.exports = {
  TRANSACTION_STATUS
}