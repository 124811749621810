const promocodes = require("./promocodes");
const math = require("./math");
const calculate = require("./calculate");
const currency = require("currency.js");

/**
 * @param {object} slot
 * @param {object = } discountsApplied
 * @param {number} priceValue
 * @param {object = } promocode
 * @param {number} paidForLength
 * @return {{discountSize: number, extraInfo: string, absoluteSize :number}}
 */

function calculateDiscount(slot, discountsApplied = [], priceValue = undefined, promocode, paidForLength = 1) {
    const price = priceValue || slot.price_value;
    let extraInfo = "";
    const discountValues = discountsApplied.map((discountName) =>
        math.calculateAbsoluteDiscountSize(slot[discountName + "_size"], slot[discountName + "_type"], price)
    );
    let absoluteDiscountSize = discountValues.reduce((discountSum, curVal) => discountSum + curVal, 0);
    let relativeDiscountSize = math.convertAbsoluteToRelative(absoluteDiscountSize, price);

    if (relativeDiscountSize > 100) {
        relativeDiscountSize = 100;
        absoluteDiscountSize =
            math.calculateAbsoluteDiscountSize(relativeDiscountSize, "percent", slot.price_value) * paidForLength;
    }

    if (promocode) {
        let promoDiscount = math.calculateAbsoluteDiscountSize(promocode.discount_size, promocode.discount_type, price)
        const relativePromoDiscount = math.convertAbsoluteToRelative(promoDiscount, price);

        if (relativeDiscountSize > 25 || relativePromoDiscount > 25) {
            if (promoDiscount > absoluteDiscountSize) {
                absoluteDiscountSize = promoDiscount;
                relativeDiscountSize = relativePromoDiscount;
            } else extraInfo = "Текущая скидка больше, чем скидка по промокоду";
        } else if (relativePromoDiscount + relativeDiscountSize <= 25) {
            absoluteDiscountSize = promoDiscount + absoluteDiscountSize;
            relativeDiscountSize = relativePromoDiscount + relativeDiscountSize;
        } else if (relativePromoDiscount + relativeDiscountSize > 25) {
            absoluteDiscountSize = math.calculateAbsoluteDiscountSize(25, "percent", price);
            relativeDiscountSize = 25;
        }
    }

    

    if (absoluteDiscountSize > price) {
        absoluteDiscountSize = price;
        relativeDiscountSize = 100;
    }

    return { discountSize: relativeDiscountSize, absoluteSize: absoluteDiscountSize, extraInfo };
}

/**
 * 
 * @param {{currency: string, size: number, type: string}} promocodeApplied 
 * @param {number} totalValue 
 * @param {number} singleValue 
 * @param {{discountSize: number, absoluteSize: number}} discount 
 * @returns 
 */

const getDiscountInfo = (promocodeApplied, totalValue, intermediateValue, singleValue) => {
    const innerDiscountSize = currency(totalValue).subtract(intermediateValue).value;
    const relativeInnerDiscountSize = math.convertAbsoluteToRelative(innerDiscountSize, totalValue);
    let promocodeSize = promocodeApplied.type === 'percent'
        ? promocodeApplied.size
        : math.convertAbsoluteToRelative(promocodeApplied.size, singleValue)

    if (promocodeSize + relativeInnerDiscountSize > 100) {
        promocodeSize = currency(100).subtract(relativeInnerDiscountSize).value
    }

    if (promocodeSize + relativeInnerDiscountSize < 25) {
        return {
            size: promocodeSize,
            exceed: false
        }
    } else {
        if (promocodeSize <= 25) {
            return {
                size: 25 - relativeInnerDiscountSize,
                exceed: true
            }
        } else {
            return {
                size: promocodeSize,
                exceed: false
            }
        }
    }
}

module.exports = {
    calculateDiscount,
    getDiscountInfo
};
