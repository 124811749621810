const currencies = [{
    "id": 1,
    "key": "USD",
    "sign": "$",
    "conversion_rate": null
}, {
    "id": 4,
    "key": "TRY",
    "sign": "₺",
    "conversion_rate": null
}, {
    "id": 2,
    "key": "RUB",
    "sign": "₽",
    "conversion_rate": null
}, {
    "id": 5,
    "key": "EUR",
    "sign": "€",
    "conversion_rate": 86.47
}, {
    "id": 6,
    "key": "AED",
    "sign": "AED",
    "conversion_rate": null
}]

const currencies_keys = {
    RUB: 'RUB',
    EUR: 'EUR',
    AED: 'AED',
}

const currencies_keys_ru = {
  RUB: 'RUB',
}

const currencies_keys_en = {
  AED: 'AED',
}

const currencies_keys_local = {
  EN: currencies_keys_en,
  RU: currencies_keys_ru,
}

const default_currency = {
  EN: currencies_keys.AED,
  RU: currencies_keys.RUB,
}

module.exports = {
    currencies,
    CURRENCIES_KEYS: currencies_keys,
    CURRENCIES_KEYS_LOCAL: currencies_keys_local,
    DEFAULT_CURRENCY: default_currency,
}