import Header from "./Header/index";
import MenuPersonalAccount from "./MenuPersonalAccount/index";
import Sidebar from "./Sidebar/index"
import Footer from "./Footer/index";
import {t} from "i18next";
import {routes as staticRoutes} from "../../../lib/routes";
import {matchPath, useLocation} from "react-router-dom";
import styles from "./index.module.scss";
import {useState} from "react";

const NavigationMenu = ({userData, hideMenu}) => {
    const [routes, setRoutes] = useState(staticRoutes);
    const location = useLocation();

    const isActiveRoute = (path) => {
        return !!matchPath(location.pathname, { path, exact: true });
    }

    return (
        <div className={`${styles.navigation_menu__body}`}>
            <div className={`card-col ${styles.navigation_menu__header}`}>
                <Header label={t('profile.navigationMenu.title')}/>
                {routes.map(route =>
                    route.headerProfile.map((sidebarItem, index) => (
                        <MenuPersonalAccount
                            key={index}
                            to={sidebarItem.to}
                            userData={userData}
                            isActive={isActiveRoute(sidebarItem.to)}
                            onClick={hideMenu}
                        />
                    ))
                )}
            </div>
            <div className={styles.navigation_menu__main}>
                <Sidebar
                    routes={routes}
                    currentPath={location.pathname}
                    onLinkClick={hideMenu}
                />
            </div>
            <div className={styles.decorative__line}></div>
            <div className={styles.navigation_menu__footer}>
                <Footer/>
            </div>
        </div>
    )
}

export default NavigationMenu