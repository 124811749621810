import valid from '../../../styles/assets/images/sv/valid.svg';
import invalid from '../../../styles/assets/images/sv/invalid.svg';
import { Input, Label } from 'reactstrap';
import { regexes } from '../../../lib/utils/helpers';
import PropTypes from 'prop-types'
import './style.scss'
import { handleInputChange } from '../../../lib/utils/handleInputChange';
import {t} from "i18next";

const Field = ({
    type,
    label,
    error,
    value,
    setValue,
    placeholder,
    fieldName,
    register,
    required = false,
    isEmailField = false,
    disabled = false,
    orientation,
    inputStyles,
    onBlur,
}) => {
    let defaultFieldStyles = {};

    if (orientation === 'horizontal') {
      defaultFieldStyles = {
        ...defaultFieldStyles,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }
    }

    const getPatternForField = (fieldName, isEmailField, regexes) => {
        if (isEmailField) {
            return regexes.emailRegex;
        }

        if (["nickname", "ruName", "enName", "ruSurname", "enSurname", "citizenship"].includes(fieldName)) {
            return regexes.nameField;
        }

        if (["pass", "enPass"].includes(fieldName)) {
            return regexes.passField;
        }

        return undefined;
    };

    const getErrorMessage = (error, fieldName, t, isEmailField) => {
        if (!error) return null;

        if (error.type === "required") {
            return t('profile.data.error.required');
        }

        if (isEmailField && error.type === "pattern") {
            return t('profile.data.error.emailPattern');
        }

        if (["nickname", "ruName", "enName"].includes(fieldName) && error.type === "pattern") {
            return t('profile.data.error.namePattern');
        }

        if (["ruSurname", "enSurname"].includes(fieldName) && error.type === "pattern") {
            return t('profile.data.error.surnamePattern');
        }

        if (["pass", "enPass"].includes(fieldName) && error.type === "pattern") {
            return t('profile.data.error.passPattern');
        }

        if (fieldName === "citizenship" && error.type === "pattern") {
            return t('profile.data.error.citizenshipPattern');
        }

        return null;
    };

    return (
        <div className="field" style={defaultFieldStyles}>
            <Label className="input-label" htmlFor={fieldName}>
                {label}
                {(error || value) && <span className="validation-img"><img src={error ? invalid : valid} alt="" /></span>}
            </Label>

                <Input
                    placeholder={placeholder}
                    id={fieldName}
                    name={fieldName}
                    value={!register && value ? value : undefined}
                    defaultValue={register && value ? value : undefined}
                    disabled={disabled}
                    onChange={e => {
                      setValue && setValue(handleInputChange(e).value);
                    }}
                    type={type || "text"}
                    innerRef={register && register({
                        required,
                        pattern: getPatternForField(fieldName, isEmailField, regexes)
                    })}
                    style={inputStyles}
                    onBlur={onBlur}
                />

            <div>
                <span className={error ? "error-label" : "error-label d-none"}>
                    {getErrorMessage(error, fieldName, t, isEmailField)}
                </span>
            </div>
        </div>
    )

}

Field.propTypes = {
    label: PropTypes.string,
    error: PropTypes.object,
    value: PropTypes.string,
    setValue: PropTypes.func,
    placeholder: PropTypes.string,
    fieldName: PropTypes.string,
    register: PropTypes.func,
    required: PropTypes.bool,
    isEmailField: PropTypes.bool,
    isBirthdateField: PropTypes.bool,
    isGenderField: PropTypes.bool,
}

export default Field