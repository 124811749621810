import { t } from 'i18next';
import React, {FC, useCallback} from 'react';
import { useInjection } from 'brandi-react';
import { observer } from 'mobx-react-lite';
import { IPromocode, TOrder } from '../../../../lib/utils/types';
import { PayBlockModelStoreToken } from '../models/PayBlockModel/index.model';
import CheckBadge from '../../badges/Success';
import {Button} from "reactstrap";
import valid from '../../../../styles/assets/images/sv/valid.svg';
import invalid from '../../../../styles/assets/images/sv/invalid.svg';

interface IPaymentPromoComponentProps {
  hideLabel?: boolean,
  applyButtonVisible?: boolean,
  verifyPromocode: (value: string, type: string) => void,
  setPromocodeApplied: (value?: IPromocode) => void,
  order: TOrder,
  placeholder?: string,
  labelText?: string,
}

const PaymentPromoComponent: FC<IPaymentPromoComponentProps> = observer((props) => {
  const {
    hideLabel,
    applyButtonVisible,
    verifyPromocode,
    setPromocodeApplied,
    order,
    placeholder,
    labelText
  } = props;

  const payBlockModel = useInjection(PayBlockModelStoreToken);

  const onClear = () => {
    setPromocodeApplied(undefined);
    payBlockModel.resetAppliedDiscounts('promocodeApplied');
  };

  const onApply = useCallback((captcha?: string) => {
    payBlockModel.verifyPromoAndCert(verifyPromocode, captcha);
  }, [verifyPromocode]);

  return (
    <div data-testid='promocode-input' className='payment-extra-block'>
      <div className='title-label'>{labelText || t('common.promocode')} 
      {(payBlockModel.promo.error || payBlockModel.promo.value) && <span className="validation-img"><img src={payBlockModel.promo.error ? invalid : valid} alt="" /></span>}
      </div>
      <div className='promo-input-block'>
        <div className="input-block-wrap">
            <input
                    type="text"
                    value={payBlockModel.promo.query}
                    onChange={e => payBlockModel.promo.setQuery(e.target.value)}
                    placeholder={placeholder || t('inputs.enterPromocode.placeholderPromo') as string}
                    className={`bordered-input mb-0 ${payBlockModel.promo.value?.status === "invalid" ? "has-error" : ""}`}
                    disabled={order?.hasPaid}
            />
            {!order?.hasPaid && <button className='clear-button' type="button" onClick={onClear}/>}
        </div>
        {payBlockModel.promo.value?.id && <CheckBadge />}
        {!!payBlockModel.promo.error && <span className='text-danger fs-12'>{payBlockModel.promo.error}</span>}
      </div>
      {applyButtonVisible && !order?.hasPaid && <Button
              color={'primary'}
              type={'button'}
              className={'form-button apply-button'}
              onClick={() => onApply()}
      >
          {t('inputs.enterPromocode.submitButton')}
      </Button>}
    </div>
  )
});

export default PaymentPromoComponent