import styles from "../../../../components/profile/NavigationMenu/index.module.scss";
import useUserData from "../../../utils/hooks/useUserData";
import NavigationMenu from "../../../../components/profile/NavigationMenu/index";
import MobileHeader from "../../../../components/profile/NavigationMenu/MobileHeader/index"
import { Col, Row, Container } from "reactstrap";
import {useState} from "react";

const WithProfileNavigation = ({children}) => {
    const userData = useUserData();
    const [menuState, setMenuState] = useState(false);

    const changeStateMenu = () => {
        setMenuState(!menuState);
    };

    return (
        <Container className={`pl-0 pr-0 ${styles.personal_account__container}`}>
            <Row className={"min-vh-100 no-gutters"}>
                <Col xs={6} className={`min-vh-100 fixed-top ${styles.navigation_menu__wrapper} ${menuState ? styles.navigation_menu__hidden : ""}`}>
                    <NavigationMenu userData={userData} hideMenu={changeStateMenu}/>
                </Col>
                <Col xs={6} className={`offset-sm-6  d-none d-lg-block ${styles.main_panel__wrapper} ${menuState ? styles.main_panel__show : ""}`}>
                    <MobileHeader showMenu={changeStateMenu}/>
                    {children}
                </Col>
            </Row>
        </Container>
    )
}

export default WithProfileNavigation