import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Label } from "reactstrap";
import Divider from "../../generic/Divider";
import FormButton from "../../generic/buttons/main";
import { useForm } from "react-hook-form";
import external from "../../../styles/assets/images/sv/external.svg";
import { handleInputChange } from "../../../lib/utils/handleInputChange";
import { usePublicEndpoints } from "../../../lib/api/usePublicEndpoints";
import PropTypes from "prop-types";
import {localizeDate, localizeFullDate} from "../../../lib/utils/format";
import { useLocation, useHistory } from "react-router-dom";
import * as _ from 'lodash';
import './style.scss';
import {features} from "../../../lib/api/globalState";
import HasPaidMessage from "../../common/HasPaidMessage";
import PaymentTimer from "../../common/PaymentTimer";
import {useLoaderState} from "../../../lib/api/loaderState";
import i18next from "i18next";
import withOrderProductExistCheck from "../../../lib/utils/hocs/withOrderProductExistCheck";
import {PRODUCT_TYPES} from "sv-common/constants/certificates";
import PayBlock from '../../common/PayBlock';
import { useInjection } from 'brandi-react';
import { observer } from 'mobx-react-lite';
import { PayBlockModelStoreToken } from '../../common/PayBlock/models/PayBlockModel/index.model';
import { sailExperience } from 'sv-common/functions/formatters';
import {sendYandexMetrikaEvent} from "../../../lib/utils/analytics";

const MembersCheckout = observer(({
    inputMembersData,
    eventData,
    step,
    order,
    isSubscriptionPay,
    subscriptionData,
    setIsSubscriptionPay,
    payingUser,
    setPromocodeApplied,
    setCertificateApplied,
    promoAndCertDiscount,
    checkOrderProduct,
    additionalDiscount,
    setOrder,
}) => {
    const payBlockModel = useInjection(PayBlockModelStoreToken);

    const { updateOrder, createPayment, getOrderTimeout } = usePublicEndpoints();
    const [chosenSlot, setChosenSlot] = useState(null);
    const [membersData, setMembersData] = useState([]);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [currentData, setCurrentData] = useState({});

    const [elapsedTime, setElapsedTime] = useState(null);
    const { pathname } = useLocation();
    const history = useHistory();

    const {setIsLoading} = useLoaderState();

    const toggleLoading = (state) => {
        setIsLoading(() => state);
        setIsSubmitDisabled(() => state);
    }

    const {t, language} = i18next;

    useEffect(() => {
        if (subscriptionData) {
            const {subscription} = subscriptionData
            setCurrentData((currentData) => (
                    {
                        ...currentData,
                        subscriptionPay: isSubscriptionPay,
                        subscriptionApplied: _.isEmpty(subscription) ? null : subscription,
                        isSubscriptionApplied: isSubscriptionPay,
                        discountedValue: 0
                    }
                )
            )
        }
    }, [isSubscriptionPay])

    useEffect(() => {
        inputMembersData.length && setMembersData(() => inputMembersData);
        if (order?.id) {
            setChosenSlot(() => order.slot);
            if (order.contactPhone) order.participants[0].phone = order.contactPhone;
            setMembersData(() => order.participants);
            setCurrentData(() => {
                return {
                    comment: order.comment,
                    publicOfferConsent: order?.paidLocally ? order?.paidLocally : order.publicOfferConsent,
                    validDataConsent: order?.paidLocally ? order?.paidLocally : order.validDataConsent,
                    priceValue: order.priceValue,
                    totalValue: order.totalValue,
                    address: order.address,
                    publicContractUrl: order.publicContractUrl,
                    returnPolicyUrl: order.returnPolicyUrl,
                    returnPolicyConsent: order.returnPolicyConsent
                };
            });
            getOrderTimeout(order.id)
                .then(res => setElapsedTime(() => res))
                .catch(e => console.log(e))
        } else {
            setChosenSlot(() => eventData?.slot);
            setIsSubmitDisabled(() => false);
        }
    }, [order?.id]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!payingUser) {
            setIsLoading(true)
        } else {
            setIsLoading(false)
        }
    }, [_.isEmpty(payingUser)])

    const { register, errors, handleSubmit, setError } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
    });

    const handleChange = (e) => {
        const { name, value } = handleInputChange(e);
        setCurrentData((currentData) => ({ ...currentData, [name]: value }));
    };

    const fondyHandler = (orderId) => {
        setIsLoading(() => true);
        createPayment(orderId, pathname + `?orderId=${orderId}&step=5`, null, payBlockModel.isCashPay())
            .then((res) => (window.location.href = res.url))
            .catch((e) => {
                checkOrderProduct(e, '/sport')
                console.log(e)
            }).finally(() => setIsLoading(false))
    };

    const handleUpdate = (localData) => {
        currentData.step = step;
        toggleLoading(true);

        if (!currentData.publicOfferConsent)
            setError('publicOfferConsent', {
                type: 'required',
                message: t('inputs.consents.publicOfferError')
            })
        if (!currentData.validDataConsent)
            setError('validDataConsent', {
                type: 'required',
                message: t('inputs.consents.validDataError')
            })

        return updateOrder(order.id, currentData)
            .then((res) => {
                if (features['SV-1569']) {
                    history.push('/error')
                } else {
                    fondyHandler(res.orderId, localData);
                }
            })
            .catch((e) => {
                console.log(e);
                toggleLoading(false);
            })
    };

    const buttonLabel = promoAndCertDiscount?.price && !order?.paidLocally ? t('common.pay') : t('common.schedule')

    const isShowSubscription = !order?.paidLocally && chosenSlot?.promo_subscription_available;

    const isShowPromocode = !order?.paidLocally && !isSubscriptionPay && !chosenSlot?.disable_promo && chosenSlot?.are_promocodes_active;

    return (
        <>
            <div className={"checkout"}>
                <div className={"checkout__main-info"}>

                    <div className="title-label">{t('sport.membersScreen.startDate')}</div>
                    <div className="checkout__date">
                        {eventData?.date ? localizeDate(eventData.date, language) : localizeDate(chosenSlot?.start_date, language)}
                        {", "}
                        {eventData?.date ? eventData.slot.start_time.slice(0, -3) : chosenSlot?.start_time.slice(0, -3)}
                    </div>
                    <div className="checkout__description">{chosenSlot?.name}</div>
                </div>
                {!order?.paidLocally && !order?.hasPaid && elapsedTime !== null &&
                    <PaymentTimer elapsedTime={elapsedTime} className="timer" setIsSubmitDisabled={setIsSubmitDisabled} />
                }
                <Divider />
                {membersData &&
                    membersData.map((item, index) => {
                        return (
                            <div key={index}>
                                <div className="checkout__member-info" key={index}>
                                    <Row className={"flex-wrap"}>
                                        <Col xs={12} sm={12} className="checkout__member-field-group">
                                          <div className="title-label">{t('inputs.email.label')}</div>
                                          <div className="checkout__member-field">{item.email}</div>
                                        </Col>
                                        <Col xs={12} sm={6} className="checkout__member-field-group">
                                            <div className="title-label">{t('inputs.firstName.label')}</div>
                                            <div className="checkout__member-field">{item.first_name}</div>
                                        </Col>
                                        <Col xs={12} sm={6} className="checkout__member-field-group">
                                            <div className="title-label">{t('inputs.lastName.label')}</div>
                                            <div className="checkout__member-field">{item.last_name}</div>
                                        </Col>
                                        <Col xs={12} sm={6} className="checkout__member-field-group">
                                            <div className="title-label">{t('inputs.phone.label')}</div>
                                            <div className="checkout__member-field">{item.phone}</div>
                                        </Col>
                                        <Col xs={12} sm={6} className="checkout__member-field-group">
                                            <div className="title-label">{t('inputs.sailingExperience.label')}</div>
                                            <div className="checkout__member-field">
                                                {t(sailExperience[item.sailing_experience])}
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={6} className="checkout__member-field-group">
                                            <div className="title-label">{t('inputs.birthdate.label')}</div>
                                            <div className="checkout__member-field">{localizeFullDate(item.birthdate, language)}</div>
                                        </Col>
                                    </Row>
                                </div>
                                <Divider />
                            </div>
                        );
                    })}
                <PayBlock
                  productType={PRODUCT_TYPES.SLOTS}
                  order={order}
                  setOrder={setOrder}
                  setCurrentData={setCurrentData}
                  additionalDiscount={additionalDiscount}
                  setCertificateApplied={setCertificateApplied}
                  setPromocodeApplied={setPromocodeApplied}
                  subscriptionData={subscriptionData}
                  isShowSubscription={isShowSubscription}
                  isShowPromocode={isShowPromocode}
                  setIsSubscriptionPay={setIsSubscriptionPay}
                  promoAndCertDiscount={promoAndCertDiscount}
                  productKey={'slot'}
                />
                {!order?.paidLocally && (isShowPromocode || isShowSubscription) && <Divider />}
                <form onSubmit={handleSubmit(handleUpdate)}>
                    <div className="checkout__input-wrapper">
                        <div className="title-label">{t('inputs.comment.label')}</div>
                        <Input
                            type="textarea"
                            id="comment"
                            name="comment"
                            onChange={handleChange}
                            innerRef={register({ required: false, maxLength: 300 })}
                            defaultValue={currentData.comment}
                            placeholder={t('inputs.comment.placeholder')}
                        />
                        <span className={errors.comment ? "error-label" : "error-label d-none"}>
                            {errors.comment?.type === "required" ? t('inputs.required') : ""}
                            {errors.comment?.type === "maxLength"
                                && t('inputs.comment.maxLength')}
                        </span>
                    </div>
                    {!order?.paidLocally && <>
                        <Divider />
                        <div className="checkout__input-wrapper radio-input">
                            <Input
                                type="checkbox"
                                id="validDataConsent"
                                defaultChecked={!!currentData.validDataConsent}
                                name="validDataConsent"
                                onChange={handleChange}
                                innerRef={register({
                                    required: true,
                                })}
                            />
                            <Label htmlFor="validDataConsent">
                                <a href={t('agreements.personalData')} target="_blank" rel="noopener noreferrer">
                                    {t('inputs.consents.validData')}
                                    <img src={external} alt="" />
                                </a>
                            </Label>
                            <span className={errors.validDataConsent ? "error-label" : "error-label d-none"}>
                            {errors.validDataConsent?.type === "required"
                                ? t("inputs.consents.validDataError")
                                : ""}
                        </span>
                        </div>
                        <div className="checkout__input-wrapper radio-input">
                            <Input
                                type="checkbox"
                                id="publicOfferConsent"
                                defaultChecked={!!currentData.publicOfferConsent}
                                name="publicOfferConsent"
                                onChange={handleChange}
                                innerRef={register({
                                    required: true,
                                })}
                            />
                            <Label htmlFor="publicOfferConsent">
                                <a href={currentData?.publicContractUrl} target="_blank" rel="noopener noreferrer">
                                    {t('inputs.consents.publicOffer')}
                                    <img src={external} alt="" />
                                </a>
                            </Label>
                            <div className={"note-label"}>{t("inputs.consents.publicOfferNote")}</div>

                            <span className={errors.publicOfferConsent ? "error-label" : "error-label d-none"}>
                            {errors.publicOfferConsent?.type === "required"
                                ? t("inputs.consents.publicOfferError")
                                : ""}
                        </span>
                        </div>
                        <div className="checkout__input-wrapper radio-input">
                            <Input
                                type="checkbox"
                                id="returnPolicyConsent"
                                defaultChecked={false}
                                name="returnPolicyConsent"
                                onChange={handleChange}
                                innerRef={register({
                                    required: true,
                                })}
                            />
                            <Label htmlFor="returnPolicyConsent">
                                <a href={`${currentData.returnPolicyUrl}`} target="_blank" rel="noopener noreferrer">
                                    {t('inputs.consents.returnPolicy')}
                                    {language === 'ru' && <br/>}
                                    {t('inputs.consents.returnPolicyTrain')}
                                    <img src={external} alt="" />
                                </a>
                            </Label>
                            <div className={"note-label"}>{t("inputs.consents.returnPolicyNote")}</div>

                            <span className={errors.returnPolicyConsent ? "error-label" : "error-label d-none"}>
                            {errors.returnPolicyConsent?.type === "required"
                                ? t('inputs.consents.returnPolicyError')
                                : ""}
                        </span>
                        </div>
                    </>}
                    <FormButton value={buttonLabel}
                                type="submit"
                                disabled={isSubmitDisabled || order?.hasPaid || !order}
                                onClick={() => {
                                    sendYandexMetrikaEvent('confirm_main');
                                }}
                    />
                    {order?.hasPaid ? <HasPaidMessage /> :
                      payBlockModel.isCardPay() && <div className='text-center h6 mt-4'>
                        {t('common.layout.payExtraCardDesc')}
                      </div>}
                </form>
            </div>
        </>
    );
});

MembersCheckout.propTypes = {
    inputMembersData: PropTypes.array,
    eventData: PropTypes.object,
    peopleAmount: PropTypes.number,
    order: PropTypes.object,
    step: PropTypes.number,
    isSubscriptionPay: PropTypes.bool,
    setIsSubscriptionPay: PropTypes.func,
    subscriptionData: PropTypes.object,
    payingUser: PropTypes.object,
    promocodeApplied: PropTypes.object,
    certificateApplied: PropTypes.object,
    setPromocodeApplied: PropTypes.func,
    setCertificateApplied: PropTypes.func,
};

export default withOrderProductExistCheck(MembersCheckout);

