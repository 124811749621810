const EVENT_TYPES = {
    TRAINING: "training",
    SPECIAL: "special",
    RACE: "race",
    INDIVIDUAL_LUCH: "individual_luch",
    INDIVIDUAL_LASER: "individual_laser",
    BASE_CHAMPIONSHIP: "base_championship",
    GRAN_PRIX: "gran_prix",
    UNIQUE: "unique",
    YACHTS: "yachts",
    YACHTS_CAMP: "yacht_camp",
    TRIP: "trip",
    MATCH_TRIP: "match_trip",
    BASICS_OF_SAILING: "basics_of_sailing",
    RACE_FOR_BEGINNERS: 'race_for_beginners',
    SAIL_GP: 'sail_gp',
    RENT: 'rent',
    COURSES: 'courses',
    SAILING_UPSKILL: 'sailing_upskill',
    SAILING_WEEKEND: 'sailing_weekend',
    BASICS_OF_RACING: 'basics_of_racing',
};

const RU_EVENT_TYPES_ALLOWED = [
    EVENT_TYPES.TRAINING,
    EVENT_TYPES.SPECIAL,
    EVENT_TYPES.RACE,
    EVENT_TYPES.INDIVIDUAL_LUCH,
    EVENT_TYPES.INDIVIDUAL_LASER,
    EVENT_TYPES.BASE_CHAMPIONSHIP,
    EVENT_TYPES.GRAN_PRIX,
    EVENT_TYPES.UNIQUE,
    EVENT_TYPES.YACHTS,
    EVENT_TYPES.MATCH_TRIP,
    EVENT_TYPES.RENT,
    EVENT_TYPES.COURSES,
]

const EN_EVENT_TYPES_ALLOWED = [
    EVENT_TYPES.TRAINING,
    EVENT_TYPES.SPECIAL,
    EVENT_TYPES.RACE_FOR_BEGINNERS,
    EVENT_TYPES.RACE,
    EVENT_TYPES.BASE_CHAMPIONSHIP,
    EVENT_TYPES.YACHTS,
    EVENT_TYPES.MATCH_TRIP,
    EVENT_TYPES.BASICS_OF_SAILING,
    EVENT_TYPES.SAIL_GP,
    EVENT_TYPES.RENT,
    EVENT_TYPES.COURSES,
    EVENT_TYPES.SAILING_UPSKILL,
    EVENT_TYPES.SAILING_WEEKEND,
    EVENT_TYPES.BASICS_OF_RACING,
]

const EVENT_TYPES_ALLOWED_COUNTRY_ADAPTER = {
    ru: RU_EVENT_TYPES_ALLOWED,
    en: EN_EVENT_TYPES_ALLOWED
}

const RU_EVENT_TYPES = {
    [EVENT_TYPES.TRAINING]: 'Тренировки',
    [EVENT_TYPES.SPECIAL]: 'Специальные тренировки',
    [EVENT_TYPES.RACE]: 'Гонки',
    [EVENT_TYPES.INDIVIDUAL_LUCH]: 'Тренировки швертботы',
    [EVENT_TYPES.INDIVIDUAL_LASER]: 'Лекции и события',
    [EVENT_TYPES.BASE_CHAMPIONSHIP]: 'Кубки базы',
    [EVENT_TYPES.GRAN_PRIX]: 'Суперкубок Силы ветра',
    [EVENT_TYPES.UNIQUE]: 'Другие',
    [EVENT_TYPES.YACHTS]: 'Прогулки на яхтах',
    [EVENT_TYPES.MATCH_TRIP]: 'Матч рейс',
    [EVENT_TYPES.BASICS_OF_SAILING]: 'Основы яхтинга',
    [EVENT_TYPES.RACE_FOR_BEGINNERS]: 'Гонка для новичков',
    [EVENT_TYPES.RENT]: 'Аренда',
    [EVENT_TYPES.COURSES]: 'Курс',
}

const EN_EVENT_TYPES = {
    [EVENT_TYPES.TRAINING]: 'Sailing experience',
    [EVENT_TYPES.SPECIAL]: 'Advanced experience',
    [EVENT_TYPES.RACE]: 'Open sailing series',
    [EVENT_TYPES.INDIVIDUAL_LUCH]: 'Individual lesson Beam',
    [EVENT_TYPES.INDIVIDUAL_LASER]: 'Individual lesson Laser',
    [EVENT_TYPES.BASE_CHAMPIONSHIP]: 'Wind Rises cup',
    [EVENT_TYPES.GRAN_PRIX]: 'Super Bowl of Wind Power',
    [EVENT_TYPES.UNIQUE]: 'Unique',
    [EVENT_TYPES.YACHTS]: 'Sailing ride',
    [EVENT_TYPES.MATCH_TRIP]: 'Coastal race',
    [EVENT_TYPES.BASICS_OF_SAILING]: 'Basics of yachting',
    [EVENT_TYPES.RACE_FOR_BEGINNERS]: 'Saturday race',
    [EVENT_TYPES.SAIL_GP]: 'Sail GP',
    [EVENT_TYPES.RENT]: 'Rent',
    [EVENT_TYPES.COURSES]: 'Course',
    [EVENT_TYPES.SAILING_UPSKILL]: 'Sailing upskill',
    [EVENT_TYPES.SAILING_WEEKEND]: 'Sailing weekend',
    [EVENT_TYPES.BASICS_OF_RACING]: 'Basics of racing',
}

const EVENT_TYPES_LANG_ADAPTER = {
    ru: RU_EVENT_TYPES,
    en: EN_EVENT_TYPES
}



module.exports = {EVENT_TYPES, EVENT_TYPES_ALLOWED_COUNTRY_ADAPTER, RU_EVENT_TYPES, EVENT_TYPES_LANG_ADAPTER}