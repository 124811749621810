export const JSONToMultipart = (json, isUploadOnlyFile) => {
  const {files, ...rest} = json;
  const formData = new FormData();
  if (files) {
    const appendFile = file => {
      if (isUploadOnlyFile) {
        formData.append(`files`, file, file.name);
      } else {
        formData.append(`files.${file.fieldName}`, file, file.name);
      }
    }

    if (!files.length || files.length === 1) {
      // in case if files is object
      const file = files[0] || files;
      if (file instanceof File) {
        appendFile(file)
      }
      else if (file.preview === null)
        rest[file.fieldName] = null
    } else {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file instanceof File) {
          appendFile(file)
        }
      }
    }
  }
  const data = JSON.stringify(rest)
  formData.append('data', data)
  return formData
}