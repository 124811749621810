const isBoolean = require("lodash/isBoolean");
const dayjs = require('dayjs');
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
dayjs.extend(isSameOrAfter);

module.exports = {
    /**
     * @param {XCertificate} certificate
     */
    isCertificateAvailable: (certificate) => {
        const hasPaid = isBoolean(certificate.has_paid) ? certificate.has_paid : true;

        const isCertificateExpired = dayjs().isAfter(certificate.available_to, 'day');
        const isCertificateStarted = certificate.available_from ? dayjs().isSameOrAfter(certificate.available_from) : false;

        return certificate.size_left > 0 && hasPaid && !isCertificateExpired && isCertificateStarted && certificate.is_active
    }
}
