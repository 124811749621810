const isNil = require("lodash/isNil");

module.exports = {
    getNonEmpty: (value) => !isNil(value) ? value : '',

    sailingExperienceFromSlotToArrival: str => {
        return {
            "0/7": "1/5",
            "1/7": "2/5",
            "2/7": "3/5",
            "3/7": "4/5",
            "4/7": "5/5",
            "5/7": "-",
            "6/7": "-",
            "7/7": "-",
        }[str]
    },

    sailingExperienceFromRuToEN: str => {
        return {
            "0/7": "1/3",
            "1/7": "2/3",
            "2/7": "3/3",
            "3/7": "3/3",
            "4/7": "3/3",
            "5/7": "3/3",
            "6/7": "3/3",
            "7/7": "3/3",
        }[str]
    },

    extractNumbers: string => ((string || '') + '').replace(/\D/g, ""),

    sailExperience: {
      "0/7": "inputs.sailingExperience.0/7",
      "1/7": "inputs.sailingExperience.1/7",
      "2/7": "inputs.sailingExperience.2/7",
      "3/7": "inputs.sailingExperience.3/7",
      "4/7": "inputs.sailingExperience.4/7",
      "5/7": "inputs.sailingExperience.5/7",
      "6/7": "inputs.sailingExperience.6/7",
      "7/7": "inputs.sailingExperience.7/7"
    }
}
