const math = require("./math");
const promocodes = require("./promocodes");
/**
 * @param {XGroup} group
 * @param {number} priceValue
 * @param {number=} paidForLength
 * @param {object = } discountsApplied
 * @param {XPromocode = } promocode
 * @return {{discountSize: number, extraInfo: string, absoluteSize :number}}
 */

function calculateDiscount(
    group, priceValue = undefined, paidForLength = 1,
    discountsApplied = [], promocode
) {
    const price = priceValue || group.price_value;

    const promoDiscount = {
        name: 'promocode',
        value: promocode ? promocodes.calculatePromoDiscount(price, promocode) : 0,
        type: promocode ? promocode.discount_type : undefined,
        currency: promocode ? promocode.discount_currency : undefined,
    };

    let discountsArray = [promoDiscount];
    let absoluteDiscount = discountsArray
        .reduce((maxDiscount, curVal) => maxDiscount.value > curVal.value ? maxDiscount : curVal);
    let relativeDiscountSize = math.convertAbsoluteToRelative(absoluteDiscount.value, price);

    if (absoluteDiscount.value > price) {
        absoluteDiscount.value = price;
        relativeDiscountSize = 100;
    }

    return {
        discountSize: relativeDiscountSize,
        absoluteSize: absoluteDiscount.value,
        extraInfo: absoluteDiscount.value ? absoluteDiscount.name : '',
        type: absoluteDiscount.type,
        currency:  absoluteDiscount.currency,
    };

}

module.exports = {
    calculateDiscount,
};
