const AUTH_FROM_STEP_EVENT = 'authFromStepEvent';
const AUTH_FROM_MENU_EVENT = 'authFromMenuEvent';
const ON_STEP_2_EVENT = 'onStep2Event';
const ON_CREATE_ORDER_EVENT = 'onCreateOrderEvent';
const ON_CREATE_PAYMENT_EVENT = 'onCreatePaymentEvent';
const ON_AFTER_PAYMENT_EVENT = 'onAfterPaymentEvent';

const EventTypesRu = {
  [AUTH_FROM_STEP_EVENT]: 'Авторизация после выбора продукта',
  [AUTH_FROM_MENU_EVENT]: 'Авторизация через меню',
  [ON_STEP_2_EVENT]: 'Брошенная корзина #eventType# - Шаг 1 - Данные о выбранном товаре',
  [ON_CREATE_ORDER_EVENT]: 'Брошенная корзина #eventType# - Шаг 2 - Данные участника',
  [ON_CREATE_PAYMENT_EVENT]: 'Брошенная корзина #eventType# - Шаг 3 - Данные заказа',
  [ON_AFTER_PAYMENT_EVENT]: 'Брошенная корзина #eventType# - Шаг 4 - Оплата',
}

const EventTypesEn = {
  [AUTH_FROM_STEP_EVENT]: 'Authorization after picked product',
  [AUTH_FROM_MENU_EVENT]: 'Authorization from menu',
  [ON_STEP_2_EVENT]: 'Abandoned cart #eventType# - Step 1 - Data of picked product',
  [ON_CREATE_ORDER_EVENT]: 'Abandoned cart #eventType# - Step 2 - Member data',
  [ON_CREATE_PAYMENT_EVENT]: 'Abandoned cart #eventType# - Step 3 - Order details',
  [ON_AFTER_PAYMENT_EVENT]: 'Abandoned cart #eventType# - Step 4 - Payment',
}

const CarrotEventTypesLangAdapter = {
  en: EventTypesEn,
  ru: EventTypesRu,
}

const CarrotEventTypes = {
  AUTH_FROM_STEP_EVENT,
  AUTH_FROM_MENU_EVENT,
  ON_STEP_2_EVENT,
  ON_CREATE_ORDER_EVENT,
  ON_CREATE_PAYMENT_EVENT,
  ON_AFTER_PAYMENT_EVENT,
}

module.exports = {
  CarrotEventTypes,
  CarrotEventTypesLangAdapter,
};