class ObjectsDifferences {
  constructor(obj1 = {}, obj2 = {}, keysTranslates = {}) {
    this.obj1 = obj1;
    this.obj2 = obj2;
    this.keysTranslates = keysTranslates;
  }
  
  getObjectsDifferences(obj1 = this.obj1, obj2 = this.obj2) {
    const differences = {};

    for (const key in obj1) {
      if (obj1.hasOwnProperty(key) && !obj2.hasOwnProperty(key)) {
        differences[key] = [obj1[key], undefined];
      } else if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
        if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
          const nestedDifferences = this.getObjectsDifferences(obj1[key], obj2[key]);
          if (Object.keys(nestedDifferences).length > 0) {
            differences[key] = nestedDifferences;
          }
        } else if (obj1[key] !== obj2[key]) {
          differences[key] = [obj1[key], obj2[key]];
        }
      }
    }

    for (const key in obj2) {
      if (obj2.hasOwnProperty(key) && !obj1.hasOwnProperty(key)) {
        differences[key] = [undefined, obj2[key]];
      }
    }

    return differences;
  }

  getFormattedString(diff) {
    const differences = diff || this.getObjectsDifferences();

    let result = '';
    for (const [key, value] of Object.entries(differences)) {
      result = `${result}${key}: ${value[0] || '-'} -> ${value[1] || '-'} \n`;
    }

    return result;
  }

  getFormattedArray(diff) {
    const differences = diff || this.getObjectsDifferences();

    let result = [];
    for (const [key, value] of Object.entries(differences)) {
      result.push(`${this.keysTranslates[key] || key}: ${value[0] || '-'} -> ${value[1] || '-'}`);
    }

    return result;
  }
}

module.exports = ObjectsDifferences;