import valid from '../../../styles/assets/images/sv/valid.svg';
import invalid from '../../../styles/assets/images/sv/invalid.svg';
import { Input, Label } from 'reactstrap';
import {localizeDate, regexes} from '../../../lib/utils/helpers';
import PropTypes from 'prop-types'
import MaskedInput from "react-input-mask";
import '../Field/style.scss'
import {Controller} from "react-hook-form";
import {t} from "i18next";

const DateField = ({
                       error,
                       value,
                       setValue,
                       label,
                       placeholder,
                       fieldName,
                       control,
                       required = false,
                       disabled = false,
                       rules={
                           required,
                           pattern: regexes.dateField,
                       }
                   }) => {

    return (
        <div className="field">
            <Label className="input-label" htmlFor={fieldName}>
                {label}
                {(error || value) && <span className="validation-img"><img src={error ? invalid : valid} alt="" /></span>}
            </Label>
            <Controller
                name={fieldName}
                control={control}
                defaultValue={value ? localizeDate(value) : ''}
                rules={rules}
                render={({ value, onChange, name, onBlur }) => (
                    <MaskedInput
                        mask="99.99.9999"
                        value={value}
                        onChange={e => {
                            onChange(e)
                            setValue && setValue(e.target.value)
                        }}
                        alwaysShowMask
                        name={name}
                        onBlur={onBlur}
                        disabled={disabled}
                    >
                        {(inputProps) => (
                            <Input
                                id={name}
                                name={name}
                                placeholder={placeholder}
                                {...inputProps}
                            />
                        )}
                    </MaskedInput>
                )}
            />


            <div>
                <span className={error ? "error-label" : "error-label d-none"}>
                    {error?.type === "required" && t('profile.data.error.required')}
                    {(fieldName === 'datePass' || fieldName === 'dateEnPass') && error?.type === 'pattern' &&  t('profile.data.error.datePassPattern')}
                </span>
            </div>
        </div>
    )

}

DateField.propTypes = {
    label: PropTypes.string,
    error: PropTypes.object,
    value: PropTypes.string,
    setValue: PropTypes.func,
    placeholder: PropTypes.string,
    fieldName: PropTypes.string,
    register: PropTypes.func,
    required: PropTypes.bool,
    isEmailField: PropTypes.bool,
    isBirthdateField: PropTypes.bool,
    isGenderField: PropTypes.bool,
}

export default DateField