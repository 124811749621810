import React, {useEffect} from 'react';
import {Row} from 'reactstrap';
import './style.scss';
import Step from "./Step";
import useQueryParamWithDefault from "../../../lib/utils/hooks/useQueryParamWithDefault";
import {NumberParam} from "use-query-params";
import {t} from "i18next";
import {sendYandexMetrikaEvent} from "../../../lib/utils/analytics";

type TStepsProps = {
    handleStepClick?: (step: number) => any,
    resetData: () => any
}

const Steps = ({handleStepClick, resetData}: TStepsProps): JSX.Element => {
    const [step, setStep] = useQueryParamWithDefault<number>('step', NumberParam, 1)
    const resetDataCb = (): void => ([3, 5].includes(step) && resetData) && resetData();

    useEffect(() => {
        const eventSent = localStorage.getItem('purchase_event_sent');

        if (step === 5 && !eventSent) {
            sendYandexMetrikaEvent('purchase_main');

            localStorage.setItem('purchase_event_sent', 'true');
        }
    }, [step]);

    return (
            <Row className={'step no-gutters pl-4 justify-content-between justify-content-lg-start d-sm-flex d-none'}>
                <Step isActive={step > 0} isPassed={step > 1} label={t('common.layout.pickDate')} step={1}
                      handleStepClick={() => {
                          if (step > 0) {
                              setStep(1);
                              handleStepClick && handleStepClick(1);
                              resetData && resetData();
                          }
                      }}/>

                <Step isActive={step > 1} isPassed={step > 2} label={t('common.layout.members')} step={2}
                      handleStepClick={() => {
                          if (step > 1) {
                              setStep(2);
                              handleStepClick && handleStepClick(2);
                          }
                          resetDataCb();
                      }}/>

                <Step isActive={step > 2} isPassed={step > 3} label={t('common.layout.confirmation')} step={3}
                      handleStepClick={() => {
                          if (step > 2) {
                              setStep(3);
                              handleStepClick && handleStepClick(3);
                          }
                      }}/>

                <Step isActive={step > 3} isPassed={step > 4} label={t('common.layout.payment')} step={4}
                      handleStepClick={() => undefined}/>

                <Step isActive={step > 4} isPassed={step > 5} label={t('common.layout.finish')} step={5}
                      handleStepClick={() => {
                          if (step > 4) {
                              setStep(5)
                              handleStepClick && handleStepClick(5)
                          }
                      }}/>
            </Row>
    )
}

export default Steps;

