export const downloadFileFromBuffer = (filename, buffer) => {
    const content = new Uint8Array(buffer)
    const blob = new Blob([content], {type: 'application/octet-binary'})

    const blobURL = (window.URL && window.URL.createObjectURL) ?
        window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = blobURL;
    a.setAttribute('download', filename);

    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    window.URL.revokeObjectURL(blobURL);
}