const currencyjs = require('currency.js')
/**
 *
 * @param {Number} basePrice
 * @param {boolean=} orderedBefore
 */
function calculateTravel(
    basePrice,
    orderedBefore
) {
    let cost = basePrice;
    if (orderedBefore) cost -= 50 * 80;
    return cost;
}

/**
 * Converts price by given conversion rate and adds 2% commision
 * @param {Number} price
 * @param {Number} conversionRate
 * @return {Number}
 */
function convertPrice(price, conversionRate) {
    return Math.ceil(currencyjs(price).multiply(conversionRate).multiply(1).value)
}

function convertPriceWithSpecifiedCommission(price, conversionRate, commission) {
    return currencyjs(price, {precision: 10}).multiply(conversionRate).multiply(commission).value
}

/**
 * Function to count price while applying discount (absolute sizes only!)
 * @param {Number} discountSize - Size of a discount in currency
 * @param {Number} value - order value to discount
 * @return {number}
 */
const countDiscountedPrice = (discountSize, value) => {
    const discount = currencyjs(value).subtract(discountSize).value
    if (discount > 1)
        return discount
    else if (discount > 0)
        return Math.ceil(discount);
    else return 0;
}

const getPartialPaymentMarkup = partPay => partPay === 20 ? 1.07 : partPay === 50 ? 1.07 : 1

const calculateMarkUp = (price, markUp) => Math.ceil(((price * 100) / (100 - markUp)) - price);

module.exports = {
    calculateTravel,
    convertPrice,
    countDiscountedPrice,
    convertPriceWithSpecifiedCommission,
    getPartialPaymentMarkup,
    calculateMarkUp,
}