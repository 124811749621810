const GROUPS = {
    COMING: 'coming',
    FINISHED: 'finished'
}

const COMMON_EVENT_STATUSES = {
    COMING: 'coming',
    FINISHED: 'finished'
}

const PRODUCT_AVAILABILITY = {
    EXPIRED: 'expired',
    AVAILABLE: 'available',
    OVERBOOKED: 'overbooked'
}

const PRODUCT_IN_ORDER_DELETED_ERROR = 'The product specified in the order has been deleted'

const TG_OPTIONS = {
  REQUIRED: 'required',
  OPTIONAL: 'optional',
  HIDDEN: 'hidden',
}

module.exports = {GROUPS, PRODUCT_AVAILABILITY, PRODUCT_IN_ORDER_DELETED_ERROR, TG_OPTIONS, COMMON_EVENT_STATUSES};
