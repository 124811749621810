import styles from './index.module.scss';
import arrowBack from '../../../../styles/assets/images/sv/arrow-back.svg';
import iconChatBlack from '../../../../styles/assets/images/sv/iconChat-black.svg';
import {t} from "i18next";
import {Link} from "react-router-dom";

const MobileHeader = ({showMenu}) => {
    return (
        <div className={styles.mobile_header__wrapper}>
            <div className={styles.mobile_header__links} onClick={showMenu}>
                <img src={arrowBack} alt=""/>
                <div className={styles.mobile_header__title_wrapper}>
                    <h3 className={styles.mobile_header__title}>{t('profile.navigationMenu.mobileHeader')}</h3>
                </div>
            </div>
            <Link to="/tablet">
                <img src={iconChatBlack} alt=""/>
            </Link>
        </div>
    )
}

export default MobileHeader;