import valid from '../../../styles/assets/images/sv/valid.svg';
import invalid from '../../../styles/assets/images/sv/invalid.svg';
import { Input, Label } from 'reactstrap';
import { regexes, localizeDate } from '../../../lib/utils/helpers';
import MaskedInput from "react-input-mask";
import PropTypes from 'prop-types'
import { Controller } from "react-hook-form";

import './style.scss'
import {t} from "i18next";

const BirthdateRHF = ({
                   error,
                   value,
                   setValue,
                   label= t('inputs.birthdate.label'),
                   placeholder= t('inputs.errorDateFormat'),
                   fieldName='birthdate',
                   control,
                   required = false,
                   disabled = false,
                   rules={
                       required,
                       pattern: regexes.birthdate,
                   }
               }) => {

    return (
        <div className="field">
            <Label className="mt-2 input-label" htmlFor={fieldName}>
                {label}:
                {(error || value) && <span className="validation-img"><img src={error ? invalid : valid} alt="" /></span>}
            </Label>

            <Controller
                name={fieldName}
                control={control}
                defaultValue={localizeDate(value)}
                rules={rules}
                render={({ value, onChange, name, onBlur }) => (
                    <MaskedInput
                        mask="99.99.9999"
                        value={value}
                        onChange={e => {
                            onChange(e)
                            setValue && setValue(e.target.value)
                        }}
                        alwaysShowMask
                        name={name}
                        onBlur={onBlur}
                        disabled={disabled}
                    >
                        {(inputProps) => (
                            <Input
                                id={name}
                                name={name}
                                placeholder={placeholder}
                                {...inputProps}
                            />
                        )}
                    </MaskedInput>
                )}
            />

            <div>
                <span className={error ? "error-label" : "error-label d-none"}>
                    {error?.type === "required" && t('inputs.required')}
                    {error?.type === "pattern" && `${t('inputs.formatError')} ${t('inputs.errorDateFormat')}`}
                </span>
            </div>
        </div>
    )

}

BirthdateRHF.propTypes = {
    label: PropTypes.string,
    error: PropTypes.object,
    value: PropTypes.string,
    setValue: PropTypes.func,
    placeholder: PropTypes.string,
    fieldName: PropTypes.string,
    register: PropTypes.func,
    control: PropTypes.object.isRequired,
    required: PropTypes.bool,
    isEmailField: PropTypes.bool,
    isBirthdateField: PropTypes.bool,
    isGenderField: PropTypes.bool,
}

export default BirthdateRHF