import styles from './style.module.scss'
import Field from '../../generic/Field'
import {useForm} from "react-hook-form";
import PhoneField from '../../common/PhoneField'
import {useState, useEffect} from 'react'
import { Col, Row } from 'reactstrap';
import FormButton from '../../generic/buttons/main';
import SelectField from '../../generic/SelectField'
import dayjs from "dayjs";
import {usePublicEndpoints} from '../../../lib/api/usePublicEndpoints';
import {useApi} from '../../../lib/api/useApi';
import {dateToISO} from "../../../lib/utils/format";
import BirthdateRHF from "../../generic/BirthdateRHF";
import {localizeDate} from "../../../lib/utils/helpers";
import GenderRHF from "../../generic/GenderRHF";
import {t} from "i18next";
import TwfaModal from './TwfaModal';
import { isRus } from '../../../lib/utils/country';
import { Constants } from 'sv-common';
import UploadingDocuments from "../../common/UploadingDocuments/index";
import DateField from "../../generic/DateField";
import useUserData from "../../../lib/utils/hooks/useUserData";

const getExperienceData = () => {
  const defaultData = [
    {
      label: t('profile.data.experience0'),
      value: '0/7'
    },
    {
      label: t('profile.data.experience1'),
      value: '1/7'
    },
    {
      label: t('profile.data.experience2'),
      value: '2/7'
    },
  ]

  if (isRus) {
    defaultData.push({
        label: t('profile.data.experience3'),
        value: '3/7'
      },
      {
        label: t('profile.data.experience4'),
        value: '4/7'
      },
      {
        label: t('profile.data.experience5'),
        value: '5/7'
      },
      {
        label: t('profile.data.experience6'),
        value: '6/7'
      },
      {
        label: t('profile.data.experience7'),
        value: '7/7'
      });
  }

  return defaultData;
}

const PersonalData = () => {
    const {register, errors, handleSubmit, control, setError, reset, setValue, trigger, formState: { dirtyFields },
    } = useForm({
        mode: "onBlur",
        reValidateMode: "onChange"
    })
    const userData = useUserData();
    // fields values
    const [phone, setPhone] = useState(userData?.phone)
    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [nickname, setNickname] = useState('')
    const [email, setEmail] = useState('')
    const [enName, setEnName] = useState('')
    const [enSurname, setEnSurname] = useState('')
    const [ruName, setRuName] = useState('')
    const [ruSurname, setRuSurname] = useState('')
    const [birthdate, setBirthdate] = useState('')
    const [gender, setGender] = useState('')
    const [pass, setPass] = useState('')
    const [enPass, setEnPass] = useState('')
    const [datePass, setDatePass] = useState('')
    const [dateEnPass, setDateEnPass] = useState('')
    const [citizenship, setCitizenship] = useState('')
    const [sailingExperience, setSailingExperience] = useState('')
    const [isSuccessfulUpdate, setIsSuccessfulUpdate] = useState(false);
    const [isUnsubscribedNews, setIsUnsubscribedNews] = useState(false);

    const [minPhoneLength, setMinPhoneLength] = useState('')
    const [isPhoneTouched, setIsPhoneTouched] = useState('');
    const [ordersStatistic, setOrdersStatistic] = useState({});

    const {updateClient, getOrdersStatistic} = usePublicEndpoints();
    const {getGlobalState} = useApi();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [presaveValues, setPresaveValues] = useState();

    useEffect(() => {
      getOrdersStatistic().then(setOrdersStatistic);
    }, []);

    useEffect(() => {
        setPhone(userData?.phone)
        setName(userData?.first_name)
        setSurname(userData?.last_name)
        setNickname(userData?.nickname)
        setEmail(userData?.email)
        setEnName(userData?.first_name_en)
        setEnSurname(userData?.last_name_en)
        setGender(userData?.gender)
        setPass(userData?.passport_num_foreign)
        setDatePass(userData?.passport_date_ru)
        setDateEnPass(userData?.passport_date_foreign)
        setCitizenship(userData?.citizenship)
        setSailingExperience(userData?.sailing_experience);
        setIsUnsubscribedNews(userData?.unsubscribed_news);
        const dataToReset = {
            phone: userData?.phone,
            name: userData?.first_name,
            surname: userData?.last_name,
            nickname: userData?.nickname,
            email: userData?.email,
            enName: userData?.first_name_en,
            enSurname: userData?.last_name_en,
            ruName: userData?.first_name,
            ruSurname: userData?.last_name,
            gender: userData?.gender,
            pass: userData?.passport_num_ru,
            enPass: userData?.passport_num_foreign,
            datePass: userData?.passport_date_ru,
            dateEnPass: userData?.passport_date_foreign,
            citizenship:  userData?.citizenship,
            sailingExperience: userData?.sailing_experience,
            unsubscribed_news: userData?.unsubscribed_news,
        }
        if (userData.birthdate) {
            setBirthdate(dayjs(userData?.birthdate).format('DD.MM.YYYY'))
            dataToReset.birthdate = localizeDate(userData?.birthdate);
        }
        reset(dataToReset);
    }, [userData])

    const handleUpdate = (userData) => {
        const dataToSend = {...userData};
        for (let k in dataToSend) {
            if (!dirtyFields[k])
                delete dataToSend[k]
        }

        if (dataToSend.birthdate) dataToSend.birthdate = dateToISO(dataToSend.birthdate);
        if (!dataToSend.gender) delete dataToSend.gender;
        const globalState = getGlobalState();

        return updateClient(globalState.userId, { ...dataToSend, code: userData.code, email: userData.email })
          .then(res => {
            if (res.status === 'updated') {
              setIsSuccessfulUpdate(true);
              setIsModalOpen(false);
            }
          })
          .catch(e => {
            if (e.response?.data?.message === 'Телефон уже занят') {
              setError('phone', {type: 'validate', message: t('profile.data.phoneTaken')})
            } else {
              throw e;
            }
          });
    }

    return (
        <div className={styles.personalData_wrapper}>
            <form
                onSubmit={(e) => {
                    handleSubmit((val) => {
                        setPresaveValues(val);
                        setIsModalOpen(true);
                    })(e);
                    e.preventDefault();
                }}
                className="personalData_form">
                <Row className={styles.personalData_form_content}>
                    <Col xs="12" className={styles.personalData__block}>
                        <div className={styles.personalData__block_title}>
                            <h4 className={styles.personalData_title}>{t('profile.data.blockTitle')}</h4>
                        </div>
                        <div className="fields">
                            <Row>
                                <Col sm="6">
                                    <Field
                                        label={t('profile.data.nicknameLable')}
                                        value={nickname}
                                        setValue={setNickname}
                                        error={errors['nickname']}
                                        placeholder={t('profile.data.nicknamePlaceholder')}
                                        fieldName="nickname"
                                        register={register}
                                        required
                                        className={`field ${styles.personalData__field}`}
                                        onBlur={() => setValue('nickname', nickname)}
                                    />
                                </Col>
                                <Col sm="6">
                                    <Field
                                        label={t('profile.data.emailLabel')}
                                        value={email}
                                        setValue={val => setEmail(val.toLowerCase())}
                                        error={errors['email']}
                                        placeholder="username@email.com"
                                        fieldName="email"
                                        register={register}
                                        required
                                        isEmailField
                                        className="field"
                                        onBlur={() => setValue('email', email)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <div className="field phone_field">
                                        <PhoneField
                                            label={t('profile.data.phoneLabel')}
                                            phoneValue={phone}
                                            error={errors['phone']}
                                            setPhoneValue={e => setPhone(e.target.value)}
                                            control={control}
                                            register={register}
                                            required
                                        />
                                        <span className={
                                            (phone?.length < minPhoneLength && isPhoneTouched)
                                            || (phone?.length < minPhoneLength && errors['phone'])
                                            || (errors['phone'])
                                                ? "error-label"
                                                : "error-label d-none"

                                        }>
                                            {errors['phone']?.message}
                                        </span>
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <BirthdateRHF value={birthdate} setValue={setBirthdate} control={control}
                                                  error={errors['birthdate']} required placeholder={t('profile.data.birthdateLabel')}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <GenderRHF
                                        control={control}
                                        value={gender}
                                        register={register}
                                        setValue={setGender}
                                        error={errors['gender']}
                                        required
                                    />
                                </Col>
                                <Col sm="6">
                                    <div className="field">
                                        <SelectField
                                            label={t('profile.data.experienceLabel')}
                                            error={errors['sailingExperience']}
                                            value={sailingExperience}
                                            setValue={setSailingExperience}
                                            fieldName="sailingExperience"
                                            register={register}
                                            defaultValue={t('profile.data.experienceDefaultLabel')}
                                            options={getExperienceData()}
                                            required
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <div className={styles.decorative__line}></div>
                <Row className={styles.personalData_form_content}>
                    <Col xs="12" className="personalData_block">
                        <div className={styles.personalData__block_subtitle}>
                            <h4 className={styles.personalData_subtitle}>{t('profile.data.passportRu')}</h4>
                        </div>
                        <div className="fields">
                            <Row>
                                <Col sm="6">
                                    <Field
                                        label={t('profile.data.ruNameLabel')}
                                        value={ruName}
                                        setValue={setRuName}
                                        error={errors['ruName']}
                                        errorMessage={t('profile.data.error.name')}
                                        placeholder="Alex"
                                        fieldName="ruName"
                                        register={register}
                                        className="field"
                                    />
                                </Col>
                                <Col sm="6">
                                    <Field
                                        label={t('profile.data.ruSurnameLabel')}
                                        value={ruSurname}
                                        setValue={setRuSurname}
                                        error={errors['ruSurname']}
                                        placeholder="Thomson"
                                        fieldName="ruSurname"
                                        register={register}
                                        className="field"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <Field
                                        label={t('profile.data.passport')}
                                        value={pass}
                                        setValue={setPass}
                                        error={errors['pass']}
                                        fieldName="pass"
                                        register={register}
                                        className="field"
                                        placeholder={t('profile.data.passportPlaceholder')}
                                    />
                                </Col>
                                <Col sm="6">
                                    <DateField
                                        error={errors['datePass']}
                                        value={datePass}
                                        setValue={setDatePass}
                                        label={t('profile.data.datePassport')}
                                        placeholder={t('profile.data.birthdateLabel')}
                                        fieldName="datePass"
                                        control={control}
                                        className="field"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <UploadingDocuments
                                        title={t('profile.data.passportFiles')}
                                        subtitle={t('profile.data.passportFilesSubtitle')}
                                        type={Constants.PRIVATE_FILE_TYPES.PASSPORT}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <div className={styles.decorative__line}></div>
                <Row className={styles.personalData_form_content}>
                    <Col xs="12" className="personalData_block">
                        <div className={styles.personalData__block_subtitle}>
                            <h4 className={styles.personalData_subtitle}>{t('profile.data.passportInternational')}</h4>
                        </div>
                        <div className="fields">
                            <Row>
                                <Col sm="6">
                                    <Field
                                        label={t('profile.data.enNameLabel')}
                                        value={enName}
                                        setValue={setEnName}
                                        error={errors['enName']}
                                        placeholder="Alex"
                                        fieldName="enName"
                                        register={register}
                                        className="field"
                                    />
                                </Col>
                                <Col sm="6">
                                    <Field
                                        label={t('profile.data.enSurnameLabel')}
                                        value={enSurname}
                                        setValue={setEnSurname}
                                        error={errors['enSurname']}
                                        placeholder="Thomson"
                                        fieldName="enSurname"
                                        register={register}
                                        className="field"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <Field
                                        label={t('profile.data.passport')}
                                        value={enPass}
                                        setValue={setEnPass}
                                        error={errors['enPass']}
                                        fieldName="enPass"
                                        register={register}
                                        className="field"
                                        placeholder={t('profile.data.passportPlaceholder')}
                                    />
                                </Col>
                                <Col sm="6">
                                    <DateField
                                        error={errors['dateEnPass']}
                                        value={dateEnPass}
                                        setValue={setDateEnPass}
                                        label={t('profile.data.dateEnPassport')}
                                        placeholder={t('profile.data.datePassportPlaceholder')}
                                        fieldName="dateEnPass"
                                        control={control}
                                        className="field"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6">
                                    <Field
                                        label={t('profile.data.citizenship')}
                                        value={citizenship}
                                        setValue={setCitizenship}
                                        error={errors['citizenship']}
                                        fieldName="citizenship"
                                        register={register}
                                        className="field"
                                        placeholder={t('profile.data.citizenshipPlaceholder')}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="12">
                                    <UploadingDocuments
                                        title={t('profile.data.passportFiles')}
                                        subtitle={t('profile.data.passportFilesSubtitle')}
                                        type={Constants.PRIVATE_FILE_TYPES.PASSPORT}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <div className={styles.decorative__line}></div>
                <div className="submitButton">
                    <FormButton
                        value={t('profile.data.save')}
                        type="submit"
                        className="small-button center"
                    />
                    {isSuccessfulUpdate && <p className="final-success">{t('profile.data.success')}</p>}
                </div>
            </form>
            {isModalOpen && <TwfaModal
                toggle={() => setIsModalOpen(!isModalOpen)}
            customHandler={(code) => handleUpdate({ ...presaveValues, code })}
            email={email}
          />}
        </div>
    )
}

export default PersonalData