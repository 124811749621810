const {PRODUCT_TYPES} = require('./certificates')


const FEEDBACK_PRODUCT_LIST = [
    {key: PRODUCT_TYPES.SLOTS, value:'Слоты'},
    {key: PRODUCT_TYPES.ARRIVALS, value:'Заезды'},
    {key: PRODUCT_TYPES.GROUPS, value:'Теория/Лекторий'},
    {key: PRODUCT_TYPES.PRACTICES, value:'Практика'},
    {key: PRODUCT_TYPES.SUBSCRIPTIONS, value:'Абонемент'},
    {key: 'certificates', value:'Сертификат'},
]

const EN_FEEDBACK_PRODUCT_LIST = [
    {key: PRODUCT_TYPES.SLOTS, value:'Slots'},
    {key: PRODUCT_TYPES.ARRIVALS, value:'Arrivals'},
    {key: PRODUCT_TYPES.GROUPS, value:'Theory/Lecture Hall'},
    {key: PRODUCT_TYPES.PRACTICES, value:'Practice'},
    {key: PRODUCT_TYPES.SUBSCRIPTIONS, value:'Season ticket'},
    {key: 'certificates', value:'Certificate'},
]

const FEEDBACK_PRODUCT_LIST_LANG_ADAPTER = {
    ru: FEEDBACK_PRODUCT_LIST,
    en: EN_FEEDBACK_PRODUCT_LIST
}

module.exports = {FEEDBACK_PRODUCT_LIST, FEEDBACK_PRODUCT_LIST_LANG_ADAPTER}