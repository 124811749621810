import {t} from "i18next";
import NavigationLink from "../NavigationLink/index"
import styles from "./index.module.scss"

const Sidebar = ({ routes, currentPath }) => {
    return (
        <nav className={styles.sidebar}>
            <ul className={styles.nav_list}>
                {routes.map(route =>
                    route.sidebarProfile.map((sidebarItem, index) => (
                        <NavigationLink
                            key={index}
                            to={sidebarItem.to}
                            name={t(sidebarItem.title)}
                            isActive={currentPath === sidebarItem.to}
                        />
                    ))
                )}
            </ul>
        </nav>
    )
}

export default Sidebar