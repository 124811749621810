import { resizeImage } from '../image';

const availableTypes = ['jpeg', 'png', 'jpg'];

const compressImage = (initialImage, maxWidth, maxHeight, maxPixels, callback) => {
  const [fileName, filetype] = initialImage.name.split('.');

  if (availableTypes.includes(filetype)) {
    const img = new Image();
    img.src = URL.createObjectURL(initialImage);
    img.onload = function(){
      const canvas = document.createElement('canvas');

      img.path = initialImage.path;
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');

      const isImageValid = resizeImage(img, maxWidth, maxHeight, maxPixels);

      if (isImageValid) {
        return callback(initialImage);
      }

      ctx.drawImage(img, 0, 0, img.width, img.height);
      canvas.toBlob(function(blob){
        const resizedImage = new File([blob], fileName + `.${filetype}`, { type: blob.type });
        resizedImage.fieldName = initialImage.fieldName;
        resizedImage.path = initialImage.path;
        return callback(resizedImage);
      }, `image/${filetype}`, 0.9);
    }
  } else {
    callback(initialImage);
  }
}

export default compressImage;