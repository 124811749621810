const GROUP_TYPES = ['theory', 'lecture']
const GROUP_TYPES_MAP = {
    THEORY: 'theory',
    LECTURE: 'lecture'
}

const RU_GROUP_TYPES = {
  [GROUP_TYPES_MAP.LECTURE]: 'Лекция',
  [GROUP_TYPES_MAP.THEORY]: 'Теория',
}

const EN_GROUP_TYPES = {
  [GROUP_TYPES_MAP.LECTURE]: 'Lecture',
  [GROUP_TYPES_MAP.THEORY]: 'Theory',
}

const GROUP_TYPES_LANG_ADAPTER = {
  ru: RU_GROUP_TYPES,
  en: EN_GROUP_TYPES,
}

module.exports = {GROUP_TYPES, GROUP_TYPES_MAP, GROUP_TYPES_LANG_ADAPTER}