import PhoneInput from 'react-phone-input-2'
import { Label } from 'reactstrap';
import valid from '../../../styles/assets/images/sv/valid.svg';
import invalid from '../../../styles/assets/images/sv/invalid.svg';
import PropTypes from "prop-types";
import { regexes } from '../../../lib/utils/helpers';
import _ from 'lodash';
import './style.scss'
import { Controller } from 'react-hook-form';
import {t} from "i18next";
import {getCountryCode} from "../../../lib/utils/helpers/formsHelpers";

const PhoneField = ({label, phoneValue, setPhoneValue, error, setMinPhoneLength, setIsTouched, index = undefined,
                    control, customOnChange, required = false, }) => {
    return (
        <>
            <Label className="input-label" htmlFor="phoneNumber">
                {label}
                {(error || phoneValue) && <span className="validation-img"><img src={error ? invalid : valid} alt="" /></span>}
            </Label>
            <Controller
                name={!_.isNil(index) ? `phone-${index}` : 'phone'}
                control={control}
                rules={{ required: true, pattern: {
                        value: regexes.phoneRegex,
                        message: t('inputs.phone.wrong')
                    } }}
                defaultValue={phoneValue}
                render={(
                    {name, value, onChange, onBlur}) => {
                    return (
                        <PhoneInput
                            index={index || ''}
                            onChange={(value, country, e) => {
                                const event = e;
                                if (!event.target.name) {
                                    event.target.name = name
                                }
                                customOnChange && customOnChange(value)
                                onChange(value);
                                if (setPhoneValue) {
                                    return setPhoneValue(event)
                                }
                            }}
                            onBlur={onBlur}
                            value={value}
                            country={getCountryCode()}
                            inputProps={{ name }}
                            isValid={(value, country) => {
                                if (!value) {
                                    return false
                                }else if (country.iso2 === 'ru') {
                                    setMinPhoneLength && setMinPhoneLength(11)
                                } else {
                                    setMinPhoneLength && setMinPhoneLength(null)
                                }
                                return true
                            }}
                        />
                    )
                }}
            />
        </>
    )
}

PhoneField.propTypes = {
    label: PropTypes.string,
    phoneValue: PropTypes.string,
    setPhoneValue:  PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    setMinPhoneLength: PropTypes.func,
    setIsTouched: PropTypes.func,
    customOnChange: PropTypes.func,
}

export default PhoneField